/*
  Description: This component controls the area of the screen just below the header
  and displays the list of tabs (e.g. Net Income, Op Ex, etc) to which the user can navigate
  to see additional detail about the project.
*/

/* Third party npm package imports */
import React, {useState} from 'react'
import {Tab, Tablist} from 'evergreen-ui'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

/* HEConomics component imports */
import PrintViewLink from './PrintViewLink'
import SummaryViewLink from './SummaryViewLink'

/* Redux action imports */
import {changePage, changeSubPage, changeNavigationArray} from '../actions'

const Navigation = () => {
  const [caseData, setCaseData] = useState(['Cases', 'Additional Investments'])
  const [reserves, setReserves] = useState([
    'Gross Oil',
    'Gross Gas',
    'Gross NGL',
    'Cum Gross Oil',
    'Cum Gross Gas',
    'Cum Gross NGL',
    'Net Oil',
    'Net Gas',
    'Net NGL',
    'Net BOE',
    'Cum Net Oil',
    'Cum Net Gas',
    'Cum Net NGL',
    'Cum Net BOE',
  ])
  const [revenue, setRevenue] = useState([
    'Total Revenue',
    'Oil Revenue',
    'Gas Revenue',
    'NGL Revenue',
  ])
  const [taxes, setTaxes] = useState([
    'Severance & Production Taxes',
    'Ad Valorem Taxes',
  ])
  const [opex, setOpex] = useState([
    'Net Total Operating Cost, Overhead & Tax',
    'Gross Total Operating Cost',
    'Net Total Operating Cost',
    'Gross Per Well Cost',
    'Net Per Well Cost',
    'Gross Oil Variable Cost',
    'Net Oil Variable Cost',
    'Gross Gas Variable Cost',
    'Net Gas Variable Cost',
    'Gross Overhead',
    'Net Overhead',
  ])
  const [netIncome, setNetIncome] = useState([
    'Net Income',
    'Cumulative Net Income',
    'Net Operating Income',
    'Cumulative Net Operating Income',
  ])
  const [investments, setInvestments] = useState([
    'Gross Investments',
    'Cum Gross Investments',
    'Net Investments',
    'Cum Net Investments',
    'PV-10 Net Investments',
    'PV-15 Net Investments',
  ])
  const [presentValue, setPresentValue] = useState([
    'PV-10 Net Income',
    'Cumulative PV-10 Net Income',
    'PV-15 Net Income',
    'Cumulative PV-15 Net Income',
    'PV Profiles',
  ])
  const [prices, setPrices] = useState([
    'Oil Base Price',
    'Gas Base Price',
    'Oil Diff',
    'Gas Diff',
    'Oil Wellhead Price',
    'Gas Wellhead Price',
    'NGL Price Percentage',
    'NGL Wellhead Price',
  ])
  const [sensitivity, setSensitivity] = useState(['Tornado'])
  // const [IRR, setIRR] = useState([]);
  // const [incremental, setIncremental] = useState([]);
  const [navigationItems, setNavigationItems] = useState([
    'Case Data',
    'Reserves',
    'Revenue',
    'OPEX',
    'Taxes',
    'Investments',
    'Net Income',
    'Present Value',
    'Prices',
    'Print View',
    'Summary View',
  ]) // REH 8/14/20 Removed Sensitivity from navigation so that overhead change could proceed to master

  const dispatch = useDispatch()
  // Use useSelector Hook to pull from Redux store
  const {mainPage} = useSelector(
    state => ({
      mainPage: state.navigation.page,
    }),
    shallowEqual,
  )

  const changeAppPage = page => {
    if (page === 'Print View' || page === 'Summary View') {
      /**
       * The Print View and Summary View tabs are special; a new page opens when
       * they are clicked, so no changePage call is needed.
       */
      return
    }

    // make showAdmin in store be false so that next time Admin is clicked, it opens Admin tab
    // if (mainPage === 'Admin' && page !== 'Admin') {
    //   dispatch(toggleAdmin())
    // }

    // For all other tabs, we call the changePage function to update the tab nav
    dispatch(changePage(page))

    switch (page) {
      case 'Case Data':
        dispatch(changeSubPage('Cases'))
        dispatch(changeNavigationArray(caseData))
        break
      case 'Reserves':
        dispatch(changeSubPage('Gross Oil'))
        dispatch(changeNavigationArray(reserves))
        break
      case 'Revenue':
        dispatch(changeSubPage('Total Revenue'))
        dispatch(changeNavigationArray(revenue))
        break
      case 'Taxes':
        dispatch(changeSubPage('Severance & Production Taxes'))
        dispatch(changeNavigationArray(taxes))
        break
      case 'OPEX':
        dispatch(changeSubPage('Net Total Operating Cost, Overhead & Tax'))
        dispatch(changeNavigationArray(opex))
        break
      case 'Net Income':
        dispatch(changeSubPage('Net Income'))
        dispatch(changeNavigationArray(netIncome))
        break
      case 'Investments':
        dispatch(changeSubPage('Gross Investments'))
        dispatch(changeNavigationArray(investments))
        break
      case 'Present Value':
        dispatch(changeSubPage('PV-10 Net Income'))
        dispatch(changeNavigationArray(presentValue))
        break
      case 'Prices':
        dispatch(changeSubPage('Oil Base Price'))
        dispatch(changeNavigationArray(prices))
        break
      case 'Sensitivity':
        dispatch(changeSubPage('Tornado'))
        dispatch(changeNavigationArray(sensitivity))
        break
      // case 'IRR':           dispatch(changeSubPage('Example 1'));
      //                       dispatch(changeNavigationArray(IRR));
      //                       break;
      // case 'Incremental':   dispatch(changeSubPage('Example 1'));
      //                       dispatch(changeNavigationArray(incremental));
      // break;
      default:
        break
    }
  }

  return (
    <div className="navigation navigation-background">
      <Tablist>
        {navigationItems.map(tab => (
          <Tab
            aria-label={tab}
            key={tab}
            is="div"
            id={tab}
            isSelected={tab === mainPage}
            onSelect={() => changeAppPage(tab)}
          >
            {tab === 'Print View' ? (
              <PrintViewLink tabName={tab} />
            ) : tab === 'Summary View' ? (
              <SummaryViewLink tabName={tab} />
            ) : (
              <>{tab}</>
            )}
          </Tab>
        ))}
        {/*<Tab><SummaryViewLink tabName='Summary View' /></Tab>*/}
      </Tablist>
      {/*<Link className="summary-link" to="/summary" target="_blank" rel="noopener noreferrer">Summary View</Link> */}
    </div>
  )
}

export default Navigation
