import * as React from 'react'
import {connect} from 'react-redux'
import {navigate} from 'gatsby'
import moment from 'moment'
import {toaster} from 'evergreen-ui'

import Admin from './Admin'
import CaseData from './caseData'
import CashFlowScreen from './CashFlow'
import Inputs from './inputs'
import LoadProjectModal from './LoadProjectModal'
import LoadingIndicator from './LoadingIndicator'
import LoginForm from './LoginForm'
import Navigation from './navigation'
import NetIncome from './NetIncome'
import OpEx from './OpEx'
import Investments from './Investments'
import PresentValue from './PresentValue'
import Prices from './Prices'
import Sensitivity from './Sensitivity'
import Reserves from './NewReserves'
import Revenue from './Revenue'
import SubNavigation from './subnavigation'
import Taxes from './Taxes'
import UserSettings from './UserSettings'

import {
  completeLoginCheck,
  getDifferentials,
  getPrices,
  getProjectIds,
  getProjects,
  getProjectSingle,
  getSettings,
  getTaxes,
  loadProject,
  postUser,
  updateLogin,
  updateUser,
  updateProjectInputs,
  validateBrowser,
  setIsLoadingDataFromCloud,
  setLoadingDataFromCloudMessage,
} from '../actions'

import 'bootstrap/dist/css/bootstrap.min.css'

const jwtDecode = require('jwt-decode')
const {detect} = require('detect-browser')

class App extends React.Component {
  componentDidMount = async () => {
    // USER DATA CHECK SECTION - Verify user before showing any data to screen
    let user
    let defaultProject = {}
    // KTE, 4/22/2021:  I decided to NOT store/retrieve the defaultProject in localStorage
    // let defaultProject = localStorage.getItem('defaultProject')
    // defaultProject =
    //   defaultProject && defaultProject !== 'undefined'
    //     ? JSON.parse(defaultProject)
    //     : {}

    let dependencies = []
    const azureAdToken = localStorage.getItem('msal.idtoken')
    const localUserData = JSON.parse(localStorage.getItem('user'))
    this.props.updateUser(localUserData)
    const expirationTime =
      localUserData?.expiration ||
      moment()
        .add(1, 'months')
        .valueOf()
    const currentTime = moment() // Right now
    const isUserSessionActive = currentTime.isBefore(moment(expirationTime))
    console.log('User session still valid?', isUserSessionActive)

    // Detect user's browser and determine if it is valid.
    const browser = detect()

    if (browser?.name === 'chrome') {
      this.props.validateBrowser(true)
    } else {
      this.props.validateBrowser(false)
    }

    // Decode Azure AD Id Token
    if (azureAdToken && isUserSessionActive) {
      // Decode user token to find username and name properties
      const {name, preferred_username} = jwtDecode(azureAdToken)

      // Prevent users from non-Hilcorp domains from logging in
      if (name && preferred_username?.includes('hilcorp.com')) {
        // Store user data in local storage and add expiration date if one does not already exist
        user = {
          userName: preferred_username.split('@')[0] || '',
          name,
          userType: 'federated',
          expiration: expirationTime,
        }

        this.props.updateLogin(true)
        this.props.completeLoginCheck()

        // KTE, 4/22/2021:  I decided to NOT store/retrieve the defaultProject in localStorage

        // DATA LOAD SECTION
        try {
          // Call APIs to retrieve latest data for HEConomics
          this.props.setIsLoadingDataFromCloud(true)
          this.props.setLoadingDataFromCloudMessage(
            'Loading user settings, price forecasts and taxes...',
          )
          this.props.getDifferentials()
          this.props.postUser(user.userName)

          // Default project loading from API requires user settings, projects, taxes and prices, so we await their promise resolution here.
          dependencies.push(this.props.getSettings())
          dependencies.push(this.props.getTaxes())
          dependencies.push(this.props.getPrices())
          Promise.all(dependencies).then(results => {
            this.props.setIsLoadingDataFromCloud(false)
            this.props.setLoadingDataFromCloudMessage('')
            // grab user's role from settings and update Redux and localStorage
            console.log(
              'In app.js, just grabbed cloud-based data.  In the promise.then object.  Here are the results...',
              results,
            )
            let settingsArray = results[0]
            let taxInfoArray = results[1]
            console.log('app, taxInfoArray:  ', taxInfoArray)
            let priceForecasts = results[2] //asdf update
            if (results[0]?.find(item => item.User === user.userName)?.Role) {
              user.role = results[0].find(
                item => item.User === user.userName,
              )?.Role
            }

            this.props.updateUser(user)
            localStorage.setItem('user', JSON.stringify(user))
            let defaultProjectName, userForecasts

            if (settingsArray) {
              defaultProjectName = settingsArray.find(
                settingsObject => settingsObject.User === user.userName,
              )?.defaultProject //||
              // results[0].find(item => item.User === 'GLOBAL')?.defaultProject
              // KTE, 4/22/2021: I decided NOT to load a global default, since
              // it feels like it would often be too different than a typical
              // user experience.  Instead, the user should load a global
              // template/starter via the Load functionality.
              // GODO, KTE, 4/22/2021:  Coach the users on the above...  maybe
              // via pop-up notifications???
              console.log('Default project name:', defaultProjectName)
              userForecasts =
                settingsArray.find(
                  settingsObject => settingsObject.User === user.userName,
                )?.priceForecasts || []
              // ?.defaultProject || []
              priceForecasts = [...priceForecasts, ...userForecasts]
              console.log(
                'GoDo from app.js line 220:  confirm that priceForecasts blend correctly.  Here is the priceForecasts array that I just created...',
                priceForecasts,
              )
            }

            if (defaultProjectName) {
              const loadingMessage =
                'Loading default project for ' +
                user.userName +
                '.  The project name ' +
                "is '" +
                defaultProjectName +
                '`.'
              defaultProject = this.props.getProjectSingle(
                defaultProjectName,
                user.userName,
                taxInfoArray,
                loadingMessage,
              )
            }
          })
        } catch (err) {
          console.log('Error loading data:', err.message)
        }
      } else {
        if (!name) {
          console.log('Unable to retrieve authenticated user.')
        } else {
          console.log('This app supports Hilcorp users only.')
        }

        this.props.updateLogin(false)
        this.props.completeLoginCheck()
        localStorage.clear()
      }
    } else {
      this.props.updateLogin(false)
      this.props.completeLoginCheck()
      localStorage.clear()
    }
  }

  showToaster = (message, id) => {
    try {
    toaster?.danger(message, {duration: 8, id})
    } catch(err) {
      console.log("Toaster error:", err);
    }
  }

  render() {
    const {cases, page, status, errors, fields, project} = this.props
    const {loggedIn, loginCheckComplete} = status

    if (typeof window !== `undefined`) {
      if (window?.location?.pathname.includes('/print')) {
        navigate('/print/index.html')
      }
    }

    // Returns empty app until user authenticates and login check completes.
    if (!loggedIn && !loginCheckComplete) {
      return <></>
    }

    // Since we bypass the login screen now, this code simply allows us to programmatically call the signIn method that is still
    // connected to the LoginForm component.
    if (!loggedIn) {
      return <LoginForm />
    }
    return (
      <div className="main-app-section">
        {errors?.cashflow?.message &&
          this.showToaster(errors?.cashflow?.message, 'cashflow-error')}
        {errors?.taxes?.message &&
          this.showToaster(errors?.taxes?.message, 'taxes-input-error')}
        {errors?.prices?.message &&
          this.showToaster(errors?.prices?.message, 'price-input-error')}
        {errors?.projectName?.message &&
          this.showToaster(errors?.projectName?.message, 'project-name-error')}
        {errors?.project?.message &&
          this.showToaster(errors?.project?.message, 'project-input-error')}
        {errors?.pricing?.message &&
          this.showToaster(errors?.pricing?.message, 'project-input-error')}
        {errors?.defaults?.message &&
          this.showToaster(errors?.defaults?.message, 'defaults-error')}
        {errors?.caseInputs?.message &&
          this.showToaster(errors?.caseInputs?.message, 'case-input-error')}

        {this.props.status.isLoadingDataFromCloud && (
          <LoadingIndicator
            message={this.props.status.loadingDataFromCloudMessage}
          />
        )}
        {
          <LoadProjectModal
            projectIds={this.props.projectIds}
            isLoadingProjectDetails={this.props.status.isLoadingProjectDetails}
            loadingProjectDetailsMessage={
              this.props.status.loadingProjectDetailsMessage
            }
          />
        }
        {<Navigation />}
        <div className="app-grid">
          <div className="subnavigation">
            <SubNavigation />
          </div>
          <div className="screens-grid">
            {page === 'Case Data' && <Inputs />}
            {page === 'Case Data' && <CaseData cases={cases} />}
            {page === 'Reserves' && <Reserves />}
            {page === 'Net Income' && <NetIncome />}
            {page === 'Investments' && <Investments />}
            {page === 'Revenue' && <Revenue />}
            {page === 'OPEX' && <OpEx />}
            {page === 'Taxes' && <Taxes />}
            {page === 'Present Value' && <PresentValue />}
            {page === 'Prices' && <Prices />}
            {page === 'Sensitivity' && <Sensitivity />}
            {page === 'Case Data' && <CashFlowScreen />}
            {page === 'User' && <UserSettings />}
            {page === 'Admin' && <Admin />}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    page: state.navigation.page,
    status: state.status,
    cases: state.cases,
    results: state.results,
    pricingInputs: state.pricingInputs,
    project: state.projectInputs,
    projects: state.projects,
    projectIds: state.projectIds,
    user: state.user,
    errors: state.errors,
    settings: state.settings,
    taxes: state.taxes,
    prices: state.prices,
  }
}

const mapDispatchToProps = {
  completeLoginCheck,
  getDifferentials,
  getPrices,
  getProjectIds,
  getProjects,
  getProjectSingle,
  getSettings,
  getTaxes,
  loadProject,
  postUser,
  updateLogin,
  updateUser,
  validateBrowser,
  updateProjectInputs,
  setIsLoadingDataFromCloud,
  setLoadingDataFromCloudMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
