/* NPM module imports */
import React, { useState } from 'react'
import { TextInput, Select } from 'evergreen-ui'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'

import TooltipIcon from './TooltipIcon'

/* Import actions for Redux */
import {
  clearPricingInputs,
  updateCustomPricesBulk,
  updateProjectInputs,
  updatePricingInputName,
  updatePricingInputsBulk,
  updateValues,
  changePage,
  changeSubPage,
  changeNavigationArray,
} from '../actions'

import {
  defaultCaseInputValues,
  numberFormatter,
  CASE_HEADERS_INPUT_ONLY,
  MAJOR_PHASES,
  STATES,
  NUMERIC_PROJECT_INPUTS,
  currencyFormatter,
  sortMap,
} from '../utils'

import '../styles/inputs.css'

import R, { project } from 'ramda'
import { USER_SETTINGS_NAVIGATION_ARRAY } from './SettingsButton'
import { PricingInputsType } from './PrintView'

const CREATE_CUSTOM_PRICE_FORECAST = 'Create Custom Forecast'

export type RoleNameType = 'Administrator' | 'User'

export type UserType = {
  userName: string
  name?: string
  userType?: string
  expiration?: number
  role?: RoleNameType
}

export type SettingsType = {
  User: string
  Role: RoleNameType
  PriceForecasts?: PricingInputsType[]
  defaultProject?: string
  defaultProjectOwner?: string
}

export type SettingsArrayType = SettingsType[]

const getCustomPriceForecast = (
  user: UserType,
  settingsArray: SettingsArrayType,
  priceName: string
): PricingInputsType | undefined =>
  settingsArray
    .find((settingsItem) => settingsItem.User === user.userName)
    ?.PriceForecasts?.find(
      (priceForecast) => priceForecast.PriceName === priceName
    )

const Inputs = () => {
  const [pricesHeader, setPricesHeader] = useState('')

  // Use useSelector Hook to pull from Redux store
  const {
    defaults,
    prices,
    pricingInputs,
    projectInputs,
    taxes,
    errors,
    settings,
    user,
  } = useSelector(
    (state) => ({
      projectInputs: state.projectInputs,
      pricingInputs: state.pricingInputs,
      taxes: state.taxes,
      defaults: state.defaults,
      prices: state.prices,
      errors: state.inputErrors,
      settings: state.settings,
      user: state.user,
    }),
    shallowEqual
  )

  console.log(projectInputs);

  const dispatch = useDispatch()

  const handleChange = (event) => {
    let stateInfo, fieldValues
    let { name, value } = event.target

    if (NUMERIC_PROJECT_INPUTS.includes(name)) {
      dispatch(updateProjectInputs(name, value.toString()))
    } else {
      switch (name) {
        case 'state':
          stateInfo =
            taxes.find((item) => item.FullName === value) ||
            taxes.find((item) => item.FullName === 'Not Specified')
          if (stateInfo) {
            dispatch(
              updateProjectInputs(
                'gasSevTaxRatePercent',
                stateInfo.gasSevTaxRatePercent
              )
            )
            dispatch(
              updateProjectInputs('gasProdTaxRate', stateInfo.gasProdTaxRate)
            )
            dispatch(
              updateProjectInputs(
                'oilSevTaxRatePercent',
                stateInfo.oilSevTaxRatePercent
              )
            )
            dispatch(
              updateProjectInputs('oilProdTaxRate', stateInfo.oilProdTaxRate)
            )
            dispatch(
              updateProjectInputs(
                'nglSevTaxRatePercent',
                stateInfo.nglSevTaxRatePercent
              )
            )
            dispatch(
              updateProjectInputs('nglProdTaxRate', stateInfo.nglProdTaxRate)
            )
          }
          break
        case 'field':
          fieldValues = defaults.find((field) => field.FieldName === value)
          if (fieldValues) {
            CASE_HEADERS_INPUT_ONLY.map((item) => {
              dispatch(
                updateValues(
                  item,
                  'opexVariableGasGross',
                  fieldValues?.varOpexGas?.toString()
                )
              )
              dispatch(
                updateValues(
                  item,
                  'opexPerWellGross',
                  fieldValues?.fixedWellCost?.toString()
                )
              )
              dispatch(
                updateValues(
                  item,
                  'overheadGross',
                  fieldValues?.overheadCost?.toString()
                )
              )
              dispatch(
                updateValues(
                  item,
                  'opexVariableOilGross',
                  fieldValues?.varOpexOil?.toString()
                )
              )
              dispatch(
                updateValues(
                  item,
                  'nglYield',
                  fieldValues?.nglYield?.toString()
                )
              )
            })
            if (fieldValues.state) {
              dispatch(updateProjectInputs('state', fieldValues?.state))
            }

            // dispatch(
            //   updateProjectInputs(
            //     'workingInterestPercent',
            //     fieldValues?.workingInterestPercent || '',
            //   ),
            // )
            // dispatch(
            //   updateProjectInputs(
            //     'netRevenueInterestPercent',
            //     fieldValues?.netRevenueInterestPercent || '',
            //   ),
            // )
            dispatch(
              updateProjectInputs(
                'adValTaxRatePercent',
                fieldValues?.adValTaxRatePercent || ''
              )
            )
            dispatch(
              updateProjectInputs('priceDiffGas', fieldValues?.priceDiffGas)
            )
            dispatch(
              updateProjectInputs('priceDiffOil', fieldValues?.priceDiffOil)
            )
            dispatch(
              updateProjectInputs(
                'nglPricePercent',
                fieldValues?.nglPricePercent
              )
            )
            dispatch(
              updateProjectInputs('shrinkPercent', fieldValues?.gasShrink)
            )
          }
          break
        default:
          break
      }

      dispatch(updateProjectInputs(name, value))
    }
  }

  const handlePricingChange = (event) => {
    const { value: priceName } = event.target

    if (priceName === CREATE_CUSTOM_PRICE_FORECAST) {
      dispatch(changePage('User'))
      dispatch(changeSubPage('Custom Price Forecasts'))
      dispatch(changeNavigationArray(USER_SETTINGS_NAVIGATION_ARRAY))
      window.scrollTo(0, 0)
      return
    }

    const customPriceForecast = getCustomPriceForecast(
      user,
      settings,
      priceName
    )

    if (
      customPriceForecast
      // settings
      //   .find(item => item.User === user.userName)
      //   ?.PriceForecasts?.find(forecast => forecast.PriceName === priceName)
    ) {
      setPricesHeader('Custom Price Forecast')
      // let filteredPrice = settings
      //   .find(item => item.User === user.userName)
      //   ?.PriceForecasts?.find(forecast => forecast.PriceName === priceName)
      // if (priceName !== 'Select Forecast') {
      // dispatch(updateCustomPricesBulk(filteredPrice))
      dispatch(
        updateCustomPricesBulk(
          defaultCaseInputValues.maxLifeYears,
          customPriceForecast
        )
      )
      dispatch(updatePricingInputName(priceName))
      // } else {
      //   dispatch(clearPricingInputs())
      // }
    } else {
      const priceForecast = prices.find(
        (price) => price.PriceName === priceName
      )
      if (priceName !== 'Select Forecast') {
        dispatch(
          updatePricingInputsBulk(
            defaultCaseInputValues.maxLifeYears,
            priceForecast
          )
        )
      } else {
        dispatch(clearPricingInputs())
      }
      setPricesHeader('Standard Price Forecast')
      dispatch(updatePricingInputName(priceName))
    }
  }

  const handleFocus = (event) => event.target.select()

  const renderRow = (title, name, tooltip) => {
    const doNotFocusItems = ['projectName', 'well', 'formation']
    const fieldDefaults = [
      // 'workingInterestPercent', // KTE, 2/16/2022:  Natalie Brent and I decided to remove working interest and net revenue interest from the batch of default-provided values.  In many fields, these numbers vary substantially, so defaults may not be useful.
      // 'netRevenueInterestPercent',
      'nglPricePercent',
      'shrinkPercent',
      'priceDiffGas',
      'priceDiffOil',
    ]
    return (
      <div key={title} className="inputs-form-row">
        <div className="inputs-column">
          {title}
          {tooltip && <TooltipIcon tooltip={tooltip} />}
        </div>
        {!doNotFocusItems.includes(name) && (
          <input
            aria-label={name}
            className={
              fieldDefaults.find((item) => item === name)
                ? 'field-input project-input'
                : 'table-input project-input'
            }
            style={
              errors.find((errorItem) => errorItem.errorKey === name)
                ? {
                    boxShadow:
                      'inset 0 0 0 2px #ec4c47, inset 0 1px 2px rgba(67, 90, 111, 0.14)',
                  }
                : {}
            }
            height={24}
            value={projectInputs[name]}
            name={name}
            width="50%"
            onChange={handleChange}
            onFocus={handleFocus}
          />
        )}
        {doNotFocusItems.includes(name) && (
          <input
            aria-label={name}
            className={
              defaults.find((item) => item.FieldName === projectInputs.field)?.[
                name
              ] === projectInputs?.[name]
                ? 'field-input project-input'
                : 'table-input project-input'
            }
            height={24}
            value={projectInputs[name]}
            name={name}
            width="50%"
            onChange={handleChange}
          />
        )}
      </div>
    )
  }

  const renderTaxRow = (title, attribute, format, tooltip) => (
    <div className="tax-info">
      <div className="tax-info-item">
        {title}
        {tooltip !== '' && <TooltipIcon tooltip={tooltip} />}
      </div>
      <input
        className="field-input project-input"
        height={25}
        width="35%"
        name={attribute}
        value={projectInputs?.[attribute]}
        style={
          errors.find((errorItem) => errorItem.errorKey === attribute)
            ? {
                boxShadow:
                  'inset 0 0 0 2px #ec4c47, inset 0 1px 2px rgba(67, 90, 111, 0.14)',
                width: '30%',
                fontSize: '14px'
              }
            : {
                width: '30%',
                fontSize: '14px'
              }
        }
        onChange={handleChange}
        onFocus={handleFocus}
      />
    </div>
    // <div className="tax-info">
    //   <div className="tax-info-item">{title}</div>
    //   <div className="tax-info-value">
    //     {(projectInputs?.[attribute] || projectInputs?.[attribute] === 0) &&
    //       `${
    //         format === 'number'
    //           ? numberFormatter(projectInputs?.[attribute], 2)
    //           : currencyFormatter(projectInputs?.[attribute], 2)
    //       } ${format === 'number' ? '%' : ''}`}
    //   </div>
    // </div>
  )

  return (
    <div className="inputs-form">
      <div className="section-header">
        <span>Project Input</span>
      </div>
      <div className="all-input-sections">
        <div className="project-inputs-full-section">
          {/* <div className="inputs-section-header">
                  Project Inputs
              </div> */}
          <div className="inputs-project-section">
            {renderRow(
              'Project Name',
              'projectName',
              'When you save this analysis, the Project Name and your user name are used to uniquely identify it among other projects.'
            )}
            <div className="inputs-form-row">
              {generateRowTitle(
                'Description',
                <div>
                  <p>
                    Desribe the type of project and how the range is quantified.
                    For example, a through-tubing plugback recompletion may be
                    described like this...
                  </p>
                  <p>
                    Thru-tbg Plugback recompletion.
                    <br />
                    P50 initial rate based on offset producer
                    <br />
                    P10-P90 rate range based on typical lognormal distribution.
                  </p>
                </div>
              )}
              <textarea
                rows={7}
                className="inputs-description"
                value={projectInputs?.description}
                name="description"
                onChange={handleChange}
              />
            </div>
            {renderRow(
              'Folder',
              'folder',
              'Optional field to allow you to group a project with similar/related projects. Will enable search by folder on load and eventually ability to generate results such as oneline exports in groups.'
            )}
            {renderRow('Well', 'well')}
            <div className="inputs-form-row">
              {generateRowTitle(
                'Field',
                <div>
                  <p>
                    The orange input boxes contain default values that are
                    loaded based on the field you select.
                  </p>
                  <p>Choose 'Not Specified' if you do not see your field.</p>
                  <p>
                    The default values are obtained from our corporate reserve
                    report analysis. The Reserves Team provides them as a
                    convenience. Overwrite the defaults as you see fit.
                  </p>
                </div>
              )}
              <select
                aria-label="field"
                height={24}
                value={projectInputs?.field}
                name="field"
                width="50%"
                onChange={handleChange}
              >
                <option key="default" value="Select Field">
                  Select Field
                </option>
                {defaults.map((field, index) => {
                  return (
                    <option key={index} value={field.FieldName}>
                      {field.FieldName}
                    </option>
                  )
                })}
              </select>
            </div>
            {renderRow('Formation', 'formation')}
            <div className="inputs-form-row">
              {generateRowTitle(
                'State',
                'The state selection affects severance and production taxes, which are shown below.  These taxes vary by state.'
              )}
              <select
                aria-label="state"
                name="state"
                value={projectInputs?.state}
                onChange={handleChange}
              >
                {STATES.map((field, index) => {
                  return (
                    <option key={index} value={field}>
                      {field}
                    </option>
                  )
                })}
              </select>
            </div>
            {interestItems.map((item) =>
              renderRow(item.title, item.name, item.tooltip)
            )}
            <div className="inputs-form-row">
              {generateRowTitle('Major Phase')}
              <select
                name="major"
                value={projectInputs?.major}
                onChange={handleChange}
              >
                {MAJOR_PHASES.map((field, index) => {
                  return (
                    <option key={index} value={field}>
                      {field}
                    </option>
                  )
                })}
              </select>
            </div>
            {renderRow(
              'Success Ps (%)',
              'successPs',
              'Ps stands for Probability of Success.  This is a percentage number that indicates the chance that your project will fall within the range of successfull outcomes that you have quantified.'
            )}
            {renderRow(
              'Shrink Percent (%)',
              'shrinkPercent',
              'Shrink represents the percentage of produced gas that is sold (Shrink % = sold volume / produced volume X 100).  Sold volume may be less than produced volume because natural gas is often burned as fuel to power compressors and generators in the field. Additionally, plant processing to remove NGLs may reduce the gas volume.'
            )}
            {renderRow(
              'Gas Price Diff ($/MCF)',
              'priceDiffGas',
              'Diff stands for Differential.  It represents the difference between the actual gas price received at the field, compared to the benchmark price.  For gas, the benchmark is typically Henry Hub.  The actual price may vary from the benchmark because of market conditions and/or costs to transport the gas.'
            )}
            {renderRow(
              'Oil Price Diff ($/BO)',
              'priceDiffOil',
              'Diff stands for Differential.  It represents the difference between the actual oil price received at the field, compared to the benchmark price.  For oil, the benchmark is typically West Texas Intermediate.  The actual price may vary from the benchmark because of market conditions, oil quality and/or costs to transport the oil.'
            )}
            {renderRow(
              'NGL Price Percent (%)',
              'nglPricePercent',
              'NGL prices historically have equaled a percentage of oil prices.  The NGL prices are calculated by multiplying the oil price forecast by this NGL price percentage.'
            )}
          </div>
        </div>
        <div className="pricing-inputs-full-section">
          <div className="inputs-section-header">Oil & Gas Price Forecast</div>
          <div className="inputs-project-section">
            <div className="inputs-form-row">
              <div className="price-selector">
                <select
                  aria-label="price-name"
                  height={24}
                  width="100%"
                  value={pricingInputs?.PriceName}
                  name="PriceName"
                  onChange={handlePricingChange}
                >
                  <option value={'Select Forecast'}>{`Select Forecast`}</option>
                  {sortMap(
                    (a, b) => a.PriceName.localeCompare(b.PriceName),
                    (item, index) => (
                      <option key={index} value={item.PriceName}>
                        Standard Prices: {item.PriceName}
                      </option>
                    ),
                    prices
                  )}
                  {sortMap(
                    (a, b) => a.PriceName.localeCompare(b.PriceName),
                    (item, index) => (
                      <option key={index} value={item.PriceName}>
                        User Prices: {item.PriceName}
                      </option>
                    ),
                    settings.find((item) => item.User === user?.userName)
                      ?.PriceForecasts
                  )}
                  <option value={CREATE_CUSTOM_PRICE_FORECAST}>
                    {CREATE_CUSTOM_PRICE_FORECAST}
                  </option>
                  {/* settings.find(item => item.User === user?.userName)?.PriceForecasts?.map((item, index) => <option key={index} value={item.PriceName}>{item.PriceName}</option>)} */}
                </select>
              </div>
            </div>
            <div className="inputs-form-row">
              <div
                style={{ width: '100%', padding: '1% 10%', fontSize: '16px' }}
              >
                {pricesHeader}
              </div>
            </div>
            <div className="inputs-form-row">
              <div className="inputs-pricing-headers pricing-header-large">
                Month
              </div>
              <div className="inputs-pricing-headers pricing-header-small">
                Oil ($/BBL)
              </div>
              <div className="inputs-pricing-headers pricing-header-small">
                Gas ($/MCF)
              </div>
            </div>
            {priceInputItems.map((item, index) => (
              <div key={index} className="inputs-form-row">
                <div className="inputs-pricing-headers pricing-header-large">
                  {item.title}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '30%',
                  }}
                >
                  {pricingInputs?.Oil?.[item.title - 1] || ''}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '30%',
                  }}
                >
                  {pricingInputs?.Gas?.[item.title - 1] || ''}
                </div>
              </div>
            ))}
            <div className="inputs-form-row">{` `}</div>
          </div>
        </div>
        <div className="taxes-inputs-full-section">
          <div className="inputs-section-header">
            Taxes
            <TooltipIcon tooltip="Severance tax is a state imposed tax on the extraction of oil & gas. Sometimes severance tax is called production tax.  States use various methods to calculate this tax.  Sometimes the tax is calculated as a percentage of revenue; sometimes the tax is calculated on a per-BBL or per-MCF basis.  As a convention, we use the term 'severance' to designate tax that is calculated as a percentage of revenue; we use the term 'production' to designate tax that is calcualted on a per-BBL or per-MCF basis.  The HEConomics app administrators maintain these numbers by state - please recruit their help if updates are needed." />
          </div>
          <div className="inputs-project-section">
            <div className="taxes-section">
              <div
                className="tax-info"
                style={{ width: '100%', justifyContent: 'center' }}
              >
                {projectInputs?.state === 'Select State'
                  ? 'Unspecified '
                  : projectInputs?.state}{' '}
                Tax Information
              </div>
              {renderTaxRow(
                'Gas Severance Tax (%)',
                'gasSevTaxRatePercent',
                'number',
                ''
              )}
              {renderTaxRow(
                'Gas Production Tax ($/MCF)',
                'gasProdTaxRate',
                'currency',
                ''
              )}
              {renderTaxRow(
                'Oil Severance Tax (%)',
                'oilSevTaxRatePercent',
                'number',
                ''
              )}
              {renderTaxRow(
                'Oil Production Tax ($/BBL)',
                'oilProdTaxRate',
                'currency',
                ''
              )}
              {renderTaxRow(
                'NGL Severance Tax (%)',
                'nglSevTaxRatePercent',
                'number',
                ''
              )}
              {renderTaxRow(
                'NGL Production Tax ($/BBL)',
                'nglProdTaxRate',
                'currency',
                ''
              )}
              {renderTaxRow(
                'Ad Valorem Tax (%)',
                'adValTaxRatePercent',
                'number',
                'Ad valorem tax is typically a county imposed tax on property.  Sometimes ad valorem tax is called property tax.  Historically, this tax was often calculated as a percentage of revenue, which is how HEConomics handles it.  It varies by field.'
              )}
              {/* <div className="tax-info">
                <div className="tax-info-item">
                  Ad Valorem Tax (%)
                  <TooltipIcon tooltip="Ad valorem tax is typically a county imposed tax on property.  Sometimes ad valorem tax is called property tax.  Historically, this tax was often calculated as a percentage of revenue, which is how HEConomics handles it.  It varies by field." />
                </div>
                <input
                  className="field-input project-input"
                  height={25}
                  width="35%"
                  fontSize="14px"
                  name="adValTaxRatePercent"
                  value={projectInputs?.adValTaxRatePercent}
                  style={
                    errors.find(
                      (errorItem) =>
                        errorItem.errorKey === 'adValTaxRatePercent'
                    )
                      ? {
                          boxShadow:
                            'inset 0 0 0 2px #ec4c47, inset 0 1px 2px rgba(67, 90, 111, 0.14)',
                          width: '30%',
                        }
                      : { width: '30%' }
                  }
                  onChange={handleChange}
                  onFocus={handleFocus}
                />
              </div> */}
              {/* <div className="tax-info">
                          <div className="tax-info-item">
                              Sev Tax Abatement (Mos):
                          </div>
                          <TextInput height={25} width={'35%'} name="sevTaxAbatement" value={projectInputs?.sevTaxAbatement} onChange={handleChange} onFocus={handleFocus} />
                      </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const interestItems = [
  {
    title: 'Working Interest (%)',
    name: 'workingInterestPercent',
    tooltip:
      'Working Interest (WI) represents the ownership percentage.  It is common in the oil & gas business to share ownership, to spread risk and share costs.  Working interest owners pay their proportionate share of the expenses, and reap their proportionate share of the revenue (less royalty).',
  },
  {
    title: 'Revenue Interest (%)',
    name: 'netRevenueInterestPercent',
    tooltip:
      'Revenue Interest is also known as Net Revenue Interest (NRI) or Royalty Interest.  It represents the percentage of the revenue reaped by the owner.  Mineral interest owners typically retain 12.5% to 20% of the revenue interest; the remaining revenue is shared proportionately by the working interest owners.',
  },
]

const priceInputItems = [
  { title: 1 },
  { title: 12 },
  { title: 24 },
  { title: 36 },
]

const generateRowTitle = (title, tooltip) => (
  <div className="inputs-column">
    {title}
    {tooltip && <TooltipIcon tooltip={tooltip} />}
  </div>
)

export default Inputs
