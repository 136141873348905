import React, {ReactNode} from 'react'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

type ArrowDescAscButtonProps = {
  onClick: () => void
  orderDescOrAsc: 'desc' | 'asc'
  children?: ReactNode
}

const ArrowDescAscButton = ({
  onClick,
  orderDescOrAsc,
  children,
}: ArrowDescAscButtonProps) => {
  return (
    <button
      style={{
        border: 'none',
        backgroundColor: 'transparent',
        padding: '2px 3px',
      }}
      onClick={() => onClick()}
    >
      {orderDescOrAsc === 'desc' ? (
        <ArrowDropDownIcon fontSize="small" />
      ) : (
        <ArrowDropUpIcon fontSize="small" />
      )}
      {children && children}
    </button>
  )
}
export default ArrowDescAscButton
