/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/* Component imports */
import CapitalInvestments from './CapitalInvestments';
import Cases from './Cases';

/* Styles specific to cases */
import '../styles/caseData.css';

const CaseData = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage } = useSelector((state) => ({
    subPage: state.navigation.subPage,
  }), shallowEqual);

  return (
    <div className="data-grid">
      {subPage === 'Cases' && <Cases />}
      {subPage === 'Additional Investments' && <CapitalInvestments />}
    </div>
  );
};

export default CaseData;
