/* NPM module imports */
import * as React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import Alert from '@material-ui/lab/Alert'

/* Component imports */
import MonthlyDataView from './MonthlyDataView'

import {
  createPlotDataObjectArrayAllCases,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils'

import '../styles/graphs.css'

const Revenue = () => {
  // Use useSelector Hook to pull from Redux store
  const {subPage, results, status} = useSelector(
    state => ({
      subPage: state.navigation.subPage,
      results: state.results,
      status: state.status,
    }),
    shallowEqual,
  )

  const netRevenueTotalPlotAllCases = createPlotDataObjectArrayAllCases(
    results,
    'revenueTotal',
  )
  const netRevenueOilPlotAllCases = createPlotDataObjectArrayAllCases(
    results,
    'revenueOil',
  )
  const netRevenueGasPlotAllCases = createPlotDataObjectArrayAllCases(
    results,
    'revenueGas',
  )
  const netRevenueNglPlotAllCases = createPlotDataObjectArrayAllCases(
    results,
    'revenueNgl',
  )

  if (!status.calcExecuted) {
    return (
      <div className="calculation-warning">
        <Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert>
      </div>
    )
  }

  return (
    <div className="full-data-grid">
      {subPage === 'Total Revenue' && (
        <MonthlyDataView
          attribute="revenueTotal"
          dataArray={netRevenueTotalPlotAllCases}
          decimals={0}
          title="Total (Oil + Gas + NGL) Revenue"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Revenue (Net $ per Month)"
        />
      )}
      {subPage === 'Oil Revenue' && (
        <MonthlyDataView
          attribute="revenueOil"
          dataArray={netRevenueOilPlotAllCases}
          decimals={0}
          title="Oil Revenue"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Oil Revenue (Net $ per Month)"
        />
      )}
      {subPage === 'Gas Revenue' && (
        <MonthlyDataView
          attribute="revenueGas"
          dataArray={netRevenueGasPlotAllCases}
          decimals={0}
          title="Gas Revenue"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Gas Revenue (Net $ per Month)"
        />
      )}
      {subPage === 'NGL Revenue' && (
        <MonthlyDataView
          attribute="revenueNgl"
          dataArray={netRevenueNglPlotAllCases}
          decimals={0}
          title="NGL Revenue"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="NGL Revenue (Net $ per Month)"
        />
      )}
    </div>
  )
}

export default Revenue
