import React from 'react';
import { Provider } from 'react-redux';

import Layout from "../components/layout";
import App from "../components/app";
import store from '../store';
import SEO from "../components/seo";
import "../styles/global.css";

const { detect } = require('detect-browser');

const IndexPage = () => {
  const browser = detect();
  console.log(browser);
  if (browser.name === 'ie') {
    return <div style={{ width: '90vw', height: '20vh', display: 'flex', justifyContent: 'center', padding: '2rem', fontSize: '16px' }}>IE is currently not supported for HEConomics usage. Please consider using Chrome instead.</div>;
  }
  return (
    <Provider store={store}>
      <Layout>
        <SEO title="HEConomics" />
        <App path="/" />
      </Layout>
    </Provider>
  );
};

export default IndexPage;
