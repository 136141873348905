import {CircularProgress, Dialog} from '@material-ui/core'
import React from 'react'

const LoadingIndicator = ({message}) => (
  <Dialog
    fullWidth
    maxWidth="md"
    //   onClose={handleClose}
    // open={status.isShowingProjectsModal}
    open={true}
  >
    <div className="loading-projects">
      <CircularProgress />
      <div style={{marginTop: '1rem'}}>{message}</div>
    </div>
  </Dialog>
)

export default LoadingIndicator
