// Config object to be passed to Msal on creation.
// For a full list of msal.js configuration parameters, 
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html

const msalConfig = {
  auth: {
    clientId: process.env.GATSBY_AZURE_AD_CLIENT_ID,
    authority: process.env.GATSBY_AZURE_AD_AUTHORITY,
    redirectUri: process.env.GATSBY_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export default msalConfig;
