/*
/ Name: OPEX
/ Purpose: Display operational expenses data in graphical and tabular form, segmented in
/ multiple ways. Returns a warning if calculations have not been run. Otherwise, shows a
/ number of subpages with graphs and tables.
*/

/* Third party NPM packages */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Imports of local components */
import MonthlyDataView from './MonthlyDataView';

/* Import utilities */
import {
  createPlotDataObjectArrayAllCases,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';

const OpEx = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, results, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    results: state.results,
    status: state.status,
  }), shallowEqual);

  const opexOverheadTaxTotalNetPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexOverheadTaxTotalNet');
  const grossOpexPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexTotalGross');
  const netOpexPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexTotalNet');
  const grossPerWellCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexPerWellGross');
  const netPerWellCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexPerWellNet');
  const grossOilVariableCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexVariableOilSumGross');
  const netOilVariableCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexVariableOilSumNet');
  const grossGasVariableCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexVariableGasSumGross');
  const netGasVariableCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'opexVariableGasSumNet');
  const netOverheadCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'overheadNet');
  const grossOverheadCostPlotAllCases = createPlotDataObjectArrayAllCases(results, 'overheadGross');

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      {subPage === 'Net Total Operating Cost, Overhead & Tax' && (
        <MonthlyDataView
          attribute="opexOverheadTaxTotalNet"
          dataArray={opexOverheadTaxTotalNetPlotAllCases}
          decimals={0}
          title="Net Total Operating Cost, Overhead & Tax"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Total Operating Cost, Overhead & Tax (Net $ per Month)"
        />
      )}
      {subPage === 'Gross Total Operating Cost' && (
        <MonthlyDataView
          attribute="opexTotalGross"
          dataArray={grossOpexPlotAllCases}
          decimals={0}
          title="Gross Total Operating Cost"
          subTitle="Gross $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Gross Total Operating Cost (Gross $ per Month)"
        />
      )}
      {subPage === 'Net Total Operating Cost' && (
        <MonthlyDataView
          attribute="opexTotalNet"
          dataArray={netOpexPlotAllCases}
          decimals={0}
          title="Net Total Operating Cost"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Total Operating Cost (Net $ per Month)"
        />
      )}
      {subPage === 'Gross Per Well Cost' && (
        <MonthlyDataView
          attribute="opexPerWellGross"
          dataArray={grossPerWellCostPlotAllCases}
          decimals={0}
          title="Gross Per Well Cost"
          subTitle="Gross $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Gross Per Well Cost (Gross $ per Month)"
        />
      )}
      {subPage === 'Net Per Well Cost' && (
        <MonthlyDataView
          attribute="opexPerWellGross"
          dataArray={netPerWellCostPlotAllCases}
          decimals={0}
          title="Net Per Well Cost"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Per Well Cost (Net $ per Month)"
        />
      )}
      {subPage === 'Gross Oil Variable Cost' && (
        <MonthlyDataView
          attribute="opexVariableOilSumGross"
          dataArray={grossOilVariableCostPlotAllCases}
          decimals={0}
          title="Gross Oil Variable Cost"
          subTitle="Gross $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Gross Oil Variable Cost (Gross $ per Month)"
        />
      )}
      {subPage === 'Net Oil Variable Cost' && (
        <MonthlyDataView
          attribute="opexVariableOilSumNet"
          dataArray={netOilVariableCostPlotAllCases}
          decimals={0}
          title="Net Oil Variable Cost"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Oil Variable Cost (Net $ per Month)"
        />
      )}
      {subPage === 'Gross Gas Variable Cost' && (
        <MonthlyDataView
          attribute="opexVariableGasSumGross"
          dataArray={grossGasVariableCostPlotAllCases}
          decimals={0}
          title="Gross Gas Variable Cost"
          subTitle="Gross $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Gross Gas Variable Cost (Gross $ per Month)"
        />
      )}
      {subPage === 'Net Gas Variable Cost' && (
        <MonthlyDataView
          attribute="opexVariableGasSumNet"
          dataArray={netGasVariableCostPlotAllCases}
          decimals={0}
          title="Net Gas Variable Cost"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Gas Variable Cost (Net $ per Month)"
        />
      )}
      {subPage === 'Gross Overhead' && (
        <MonthlyDataView
          attribute="overheadGross"
          dataArray={grossOverheadCostPlotAllCases}
          decimals={0}
          title="Overhead"
          subTitle="Gross $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Overhead (Gross $ per Month)"
        />
      )}
      {subPage === 'Net Overhead' && (
        <MonthlyDataView
          attribute="overheadNet"
          dataArray={netOverheadCostPlotAllCases}
          decimals={0}
          title="Overhead"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Overhead (Net $ per Month)"
        />
      )}
    </div>
  );
};

export default OpEx;
