/* Third party NPM packages */
import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Table, Text} from 'evergreen-ui'
import moment from 'moment'
import PropTypes from 'prop-types'

/* Import action creators for Redux */
import {getProjectSingle} from '../actions'
import ArrowDescAscButton from './ArrowDescAscButton'

/* Legacy require statements for third party packages */
const _ = require('lodash')

type OrderByType = 'LastModified' | 'Owner' | 'ProjectName' | 'Folder'

const ProjectsTable = ({isShowingOnlyMyProjects = true, projectIds = []}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [ownerSearchQuery, setOwnerSearchQuery] = useState('')
  const [folderSearchQuery, setFolderSearchQuery] = useState('')
  const [orderLastModified, setOrderLastModified] = useState<'desc' | 'asc'>(
    'desc',
  )
  const [orderOwner, setOrderOwner] = useState<'desc' | 'asc'>('desc')
  const [orderFolder, setOrderFolder] = useState<'desc' | 'asc'>('desc')
  const [orderProjectName, setOrderProjectName] = useState<'desc' | 'asc'>(
    'desc',
  )
  const [orderBy, setOrderBy] = useState<OrderByType>('LastModified')

  // Use useSelector Hook to pull from Redux store
  const {user, taxInfoArray} = useSelector(
    state => ({
      user: state.user,
      taxInfoArray: state.taxes,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  const selectProject = (projectName, projectOwner) => {
    dispatch(getProjectSingle(projectName, projectOwner, taxInfoArray))
  }

  const handleFilterChange = value => {
    setSearchQuery(value)
  }

  const handleOwnerFilterChange = value => {
    setOwnerSearchQuery(value)
  }

  const handleFolderFilterChange = value => {
    setFolderSearchQuery(value)
  }

  const handleOrderLastModifiedChange = () => {
    setOrderLastModified(orderLastModified === 'asc' ? 'desc' : 'asc')
    setOrderBy('LastModified')
  }

  const handleOrderOwnerChange = () => {
    setOrderOwner(orderOwner === 'asc' ? 'desc' : 'asc')
    setOrderBy('Owner')
  }

  const handleOrderFolderChange = () => {
    setOrderFolder(orderFolder === 'asc' ? 'desc' : 'asc')
    setOrderBy('Folder')
  }

  const handleOrderProjectNameChange = () => {
    setOrderProjectName(orderProjectName === 'asc' ? 'desc' : 'asc')
    setOrderBy('ProjectName')
  }

  const filterProjects = (projectQuery, ownerQuery, folderQuery) => {
    return project => {
      // Check if each query is non-empty and if the project matches the query
      return (!projectQuery || project.ProjectName.toLowerCase().includes(projectQuery.toLowerCase())) &&
             (!ownerQuery || project.Owner.toLowerCase().includes(ownerQuery.toLowerCase())) &&
             (!folderQuery || (project.Folder && project.Folder.toLowerCase().includes(folderQuery.toLowerCase())));
    };
  }

  const renderRow = (
    projectName,
    projectOwner,
    projectFolder,
    projectTimeLastModified,
    index,
  ) => {
    return (
      <Table.Row
        key={index}
        isSelectable
        onSelect={() => selectProject(projectName, projectOwner)}
      >
        <Table.Cell>
          <Text>{projectName}</Text>
        </Table.Cell>
        <Table.TextCell isNumber>{projectOwner}</Table.TextCell>
        <Table.Cell>
          <Text>{projectFolder || "     "}</Text>
        </Table.Cell>
        <Table.TextCell isNumber>
          {projectTimeLastModified
            ? moment(projectTimeLastModified).format('MM-DD-YYYY')
            : ' '}
        </Table.TextCell>
      </Table.Row>
    )
  }

  const determineAscOrDesc = (
    orderBy: OrderByType,
    orderLastModified: 'asc' | 'desc',
    orderOwner: 'asc' | 'desc',
    orderProjectName: 'asc' | 'desc',
    orderFolder: 'asc' | 'desc'
  ) => {
    switch (orderBy) {
      case 'LastModified':
        return orderLastModified
      case 'Owner':
        return orderOwner
      case 'ProjectName':
        return orderProjectName
      case 'Folder':
          return orderFolder
      default:
        throw new Error(
          'Invalid order type name.  The following orderBy value was provided:  ' +
            orderBy,
        )
    }
  }

  let filteredProjectIds;
  if (isShowingOnlyMyProjects) {
    // First, filter out the projects whose owner is not the current user
    filteredProjectIds = projectIds.filter(
      project =>
        project.Owner === user.userName || project.Owner === 'All Users'
    )
  } else {
    // If not only showing user's projects, assign all projects to the filtered data set
    filteredProjectIds = projectIds;
  }
  // If any of the search inputs are not blank, filter down further on the dataset
  if (searchQuery !== '' || ownerSearchQuery !== '' || folderSearchQuery !== '') {
    // Build the filter function based on current search queries
    const filterFn = filterProjects(searchQuery, ownerSearchQuery, folderSearchQuery);
    filteredProjectIds = filteredProjectIds.filter(filterFn);
  }

  filteredProjectIds = filteredProjectIds
    .filter(item => item.Owner === 'All Users')
    .concat(
      _.orderBy(
        filteredProjectIds.filter(item => item.Owner !== 'All Users'),
        [orderBy],
        [
          determineAscOrDesc(
            orderBy,
            orderLastModified,
            orderOwner,
            orderProjectName,
            orderFolder
          ),
        ],
      ),
    )

  return (
    <Table border>
      <Table.Head height="3em">
        <Table.Cell>
          <ArrowDescAscButton
            onClick={handleOrderProjectNameChange}
            orderDescOrAsc={orderProjectName}
          />
          <Table.SearchHeaderCell
            placeholder="Project Name"
            onChange={handleFilterChange}
            value={searchQuery}
          />
        </Table.Cell>
        <Table.Cell>
          <ArrowDescAscButton
            onClick={handleOrderOwnerChange}
            orderDescOrAsc={orderOwner}
          />
          <Table.SearchHeaderCell
            placeholder="Owner"
            onChange={handleOwnerFilterChange}
            value={ownerSearchQuery}
          />
        </Table.Cell>
        <Table.Cell>
          <ArrowDescAscButton
            onClick={handleOrderFolderChange}
            orderDescOrAsc={orderFolder}
          />
          <Table.SearchHeaderCell
            placeholder="Folder"
            onChange={handleFolderFilterChange}
            value={folderSearchQuery}
          />
        </Table.Cell>
        <Table.Cell>
          <ArrowDescAscButton
            onClick={handleOrderLastModifiedChange}
            orderDescOrAsc={orderLastModified}
          >
            Last Modified
          </ArrowDescAscButton>
        </Table.Cell>
      </Table.Head>
      <Table.VirtualBody height={480}>
        {filteredProjectIds.map((projectId, index) =>
          renderRow(
            projectId.ProjectName,
            projectId.Owner,
            projectId.Folder,
            projectId.LastModified,
            index,
          ),
        )}
      </Table.VirtualBody>
    </Table>
  )
}

ProjectsTable.propTypes = {
  isShowingOnlyMyProjects: PropTypes.bool,
  projects: PropTypes.array,
}

export default ProjectsTable
