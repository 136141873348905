import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { filter } from 'fuzzaldrin-plus';
import {
  Table,
  Text,
  TextDropdownButton
} from 'evergreen-ui';

import { numberFormatter } from '../utils';

const Order = {
  NONE: 'NONE',
  ASC: 'ASC',
  DESC: 'DESC'
}

const DifferentialsTable = () => {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [orderedColumn, setOrderedColumn] = React.useState(1);
    const [ordering, setOrdering] = React.useState(Order.ASC);
    const [column2Show, setColumn2Show] = React.useState('email');

  // Use useSelector Hook to pull from Redux store
  const { defaults } = useSelector((state) => ({
    defaults: state.defaults,
  }), shallowEqual);


  // Filter the items based on the name property.
  const filter = items => {
    const searchQuery = searchQuery.trim();

    // If the searchQuery is empty, return the items as is.
    if (searchQuery.length === 0) return items

    return items.filter(item => {
      // Use the filter from fuzzaldrin-plus to filter by name.
      const result = filter([item.ProjectName], searchQuery)
      return result.length === 1
    })
  }

  const handleFilterChange = value => {
    setSearchQuery(value);
  }

  const renderRow = ({ item }) => {
    return (
      <Table.Row key={item.FieldName}>
        <Table.Cell display="flex" alignItems="center" padding={6} width={180}>
          <Text marginLeft={8} size={300} width={167} fontWeight={400}>
            {item.FieldName}
          </Text>
        </Table.Cell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{numberFormatter(item.gasShrink, 2)}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.nglYield}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.priceDiffGas}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.priceDiffOil}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.varOpexOil}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.varOpexGas}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.fixedWellCost}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.overheadCost}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{`${numberFormatter(item.adValTaxRatePercent, 2)} %`}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{numberFormatter(item.nglPricePercent, 2)}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.workingInterestPercent && numberFormatter(item.workingInterestPercent, 2)}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.netRevenueInterestPercent && numberFormatter(item.netRevenueInterestPercent, 2)}</Table.TextCell>
        <Table.TextCell padding={6} textAlign="right" isNumber>{item.state}</Table.TextCell>
      </Table.Row>
    )
  }

    let items = defaults;

    if(searchQuery !== '')
    {
      items = items.filter(defaultItem => defaultItem.FieldName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1);
    }

    return (
      <Table id="admin-tables" border backgroundColor="white">
        <Table.Head height={48}>
          <Table.SearchHeaderCell 
            placeholder="Field Name"
            onChange={handleFilterChange}
            value={searchQuery}
            style={{ width: '100px' }} 
          />
          {['Gas Shrink', 'NGL Yield', 'Gas Price Diff.', 'Oil Price Diff.', 'Var. OPEX Oil', 'Var. OPEX Gas', 'Gross Fixed Well Cost', 'Overhead Cost', 'Ad Valorem Tax', 'NGL Price Factor', 'Working Interest', 'Revenue Interest', 'State'].map((item, index) => (
            <Table.TextHeaderCell key={index} textAlign="right">
              {item}
            </Table.TextHeaderCell>
          ))}
        </Table.Head>
        <Table.VirtualBody className="admin-table-body" height={624}>
          {items?.map(item => renderRow({ item }))}
        </Table.VirtualBody>
      </Table>
    )
}

export default DifferentialsTable;
