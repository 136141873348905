import * as React from 'react';

import {
  MONTHLY_CASE_DATA_HEADERS,
} from '../utils';

const DataTableHeader = () => (
  <div className="data-table-header">
    {MONTHLY_CASE_DATA_HEADERS.map((item) => (
      <div key={item} style={{ display: 'flex', justifyContent: 'center', width: '11%' }}>
        {item}
      </div>
    ))}
  </div>
);

export default DataTableHeader;
