/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Table,
  Text,
} from 'evergreen-ui'

import { 
  numberFormatter,
  currencyFormatter,
} from '../utils';

/* Legacy require statements for third party packages */
const _ = require('lodash');

const TaxesTable = () => {
  // Use useSelector Hook to pull from Redux store
  const { taxes } = useSelector((state) => ({
    taxes: state.taxes,
  }), shallowEqual);

  const renderRow = (taxItem) => {
    const { gasSevTaxRatePercent, gasProdTaxRate, oilSevTaxRatePercent, oilProdTaxRate, nglSevTaxRatePercent, nglProdTaxRate, State, FullName } = taxItem;
    return (
      <Table.Row height={40} key={State}>
        <Table.TextCell display="flex" alignItems="center">
          <Text marginLeft={8} size={300} fontWeight={400}>
            {State}
          </Text>
        </Table.TextCell>
        <Table.TextCell display="flex" alignItems="center">
            {FullName}
        </Table.TextCell>
        <Table.TextCell>{`${numberFormatter(gasSevTaxRatePercent, 2)} %`}</Table.TextCell>
        <Table.TextCell>{currencyFormatter(gasProdTaxRate, 2)}</Table.TextCell>
        <Table.TextCell>{`${numberFormatter(oilSevTaxRatePercent, 2)} %`}</Table.TextCell>
        <Table.TextCell>{currencyFormatter(oilProdTaxRate, 2)}</Table.TextCell>
        <Table.TextCell>{`${numberFormatter(nglSevTaxRatePercent, 2)} %`}</Table.TextCell>
        <Table.TextCell>{currencyFormatter(nglProdTaxRate, 2)}</Table.TextCell>
        {/* <Table.TextCell>{`${numberFormatter(adValTaxRatePercent, 2)} %`}</Table.TextCell> */}
      </Table.Row>
    )
  }

  return (
    <Table id="admin-tables" border backgroundColor="white">
      <Table.Head height={40}>
        {taxHeaders.map((item, index) => (
            <Table.TextHeaderCell key={index}>
              {item}
          </Table.TextHeaderCell>
        ))}
      </Table.Head>
      <Table.VirtualBody className="admin-table-body" height={520}>
        {taxes?.map(item => renderRow(item))}
      </Table.VirtualBody>
    </Table>
  )
}

const taxHeaders = [
  'Abbreviation',
  'State Name',
  'Gas Sev. Tax',
  'Gas Prod. Tax',
  'Oil Sev. Tax',
  'Oil Prod. Tax',
  'NGL Sev. Tax',
  'NGL Prod. Tax',
];

export default TaxesTable;
