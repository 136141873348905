import React from 'react';
import { Tab, Tablist, Table } from 'evergreen-ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import TooltipIcon from './TooltipIcon';

import {
    updateInvestments,
    updateSingleInvestments,
    addCapitalRow,
    removeCapitalRow,
    updateValues,
} from '../actions';

import { NUMERIC_VALIDATION_REGEX, numberFormatter } from '../utils';

const CapitalInvestments = () => {
 // Use useSelector Hook to pull from Redux store
  const { capitalInvestments, cases } = useSelector((state) => ({
    capitalInvestments: state.capitalInvestments,
    cases: state.cases,
  }), shallowEqual);

  const [subNavigation, setSubNavigation] = React.useState(['P90 Low Case', 'P50 Med Case', 'P10 High Case', 'Failure', 'Existing Conditions']);
  const [tab, setTab] = React.useState('P90 Low Case');

  const dispatch = useDispatch();

  const changeTab = (tab) => {
      setTab(tab);
  }

  const addRow = (caseItem, event) => {
    dispatch(addCapitalRow(caseItem, capitalInvestments));
    event.target.blur();
  }

  const removeRow = (caseItem, index) => {
    dispatch(removeCapitalRow(caseItem, index));
    dispatch(updateSingleInvestments(caseItem, capitalInvestments?.[caseItem].filter((item, arrayIndex) => arrayIndex !== index), cases));
  }

  const handleChange = (group, attribute, arrayIndex, event) => {
    // Group example values: p90, p50, 10
    // Attribute example values: Month, Investment
    const { value } = event.target;
    let newCapitalInvestmentsArray;

    if(attribute === 'Month') {
      newCapitalInvestmentsArray = capitalInvestments[group].map((item, index) => (
        index === arrayIndex ? { ...item, [attribute]: Number(value) ? Number(value) : value } : item)
      );
    } else {
      let filterCommaValue = value.replace(/,/g, '');
      if(isNaN(filterCommaValue))
      {
        newCapitalInvestmentsArray = capitalInvestments[group].map((item, index) => (
            index === arrayIndex ? { ...item, [attribute]: filterCommaValue.toString() } : item)
          );
      } else {
        newCapitalInvestmentsArray = capitalInvestments[group].map((item, index) => (
            index === arrayIndex ? { ...item, [attribute]: numberFormatter(Number(filterCommaValue)).toString() } : item)
          );
      }
    }

    console.log("Case:", group);

    // Update CapitalInvestments part of the Redux store
    dispatch(updateInvestments(group, newCapitalInvestmentsArray));
    console.log("Capital Investments:", newCapitalInvestmentsArray);

    if(value !== "")
    {
      // Update case inputs capital gross in Redux store
      dispatch(updateSingleInvestments(group, newCapitalInvestmentsArray, cases));
    }
  }

  const handleFocus = (event) => event.target.select();

  const handleInitialInvestmentChange = (group, event) => {
    let { name, value } = event.target;

    let newCapitalGrossArray = [ ...cases[group].capitalGross ]
    let filterCommaValue = value.replace(/,/g, '');
    if(isNaN(filterCommaValue))
    {
      newCapitalGrossArray[0] = filterCommaValue.toString();
    } else {
      newCapitalGrossArray[0] = numberFormatter(Number(filterCommaValue)).toString();
    }
    dispatch(updateValues(group, name, newCapitalGrossArray));
  }

  const keyPressed = (event) => {
    if(event.key === "Enter")
    {
      event.target.blur();
    }
  }

  const postToCapitalGross = (group) => {
    dispatch(updateSingleInvestments(group, capitalInvestments, cases));
  }

  const renderCapitalInvestmentTable = (caseItem) => {
    return (
      <div className="capital-investments-table" style={{ margin: '1rem 0' }}>
        <Table border>
          <Table.Head className="reserves-tableHead" height={40}>
            {['Month', 'Gross Investment ($)', 'Action'].map((item, index) => {
              return (
                <Table.TextHeaderCell textAlign="center" key={index} width="10%">
                  {item}
                </Table.TextHeaderCell>
              )
            })}
          </Table.Head>
          <Table.Body>
            {renderInitialInvestmentRow(caseItem)}
            {renderRemainingInvestments(caseItem)}
            <Table.Row>
              <Table.TextCell>
              </Table.TextCell>
              <Table.TextCell>
              </Table.TextCell>
              <Table.TextCell>
                <button
                  className="clear-link remove-investment-button"
                  onClick={(e) => addRow(caseItem, e)}
                >
                  Add Investment
                </button>
              </Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    )
  }

  const renderInitialInvestmentRow = (caseItem) => {
    return (
      <Table.Row>
        <Table.TextCell>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            1
            <TooltipIcon tooltip="Month 1 represents the Gross Month 1 Investment that is also editable on the Cases tab."/>
          </div>
        </Table.TextCell>
        <Table.TextCell>
          <input
            name="capitalGross"
            className="capital-investment-item"
            value={cases?.[caseItem]?.capitalGross && cases?.[caseItem]?.capitalGross[0] || 0}
            onChange={(e) => handleInitialInvestmentChange(caseItem, e)}
            onFocus={handleFocus}
            onKeyDown={keyPressed}
          />
        </Table.TextCell>
        <Table.TextCell>
            {` `}
        </Table.TextCell>
      </Table.Row>
    )
  }

  const renderRemainingInvestments = (caseItem) => {
    return capitalInvestments?.[caseItem]?.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.TextCell>
            <input
              className={item.Month.toString().match(NUMERIC_VALIDATION_REGEX) || item.Month === '' ? "invalid-input invalid-input-large yellow-input" : "capital-investment-item"}
              value={item.Month !== 0 ? item.Month: ''}
              name="Month"
              onChange={(e) => handleChange(caseItem, 'Month', index, e)}
              onBlur={() => postToCapitalGross(caseItem)}
              onFocus={handleFocus}
              onKeyDown={keyPressed}
            />
          </Table.TextCell>
          <Table.TextCell>
              <input
                className={item.Investment.toString().match(NUMERIC_VALIDATION_REGEX) || item.Investment === '' ? "invalid-input invalid-input-large yellow-input" : "capital-investment-item"}
                value={item.Investment}
                onChange={(e) => handleChange(caseItem, 'Investment', index, e)}
                onFocus={handleFocus}
                onKeyDown={keyPressed}
              />
          </Table.TextCell>
          <Table.TextCell>
            <button
              onClick={() => removeRow(caseItem, index)}
              className="remove-investment-button"
            >
              Remove
            </button>
          </Table.TextCell>
        </Table.Row>
      )
    })
  }

  return (
    <div className="capital-investments">
      <div className="capital-investments-tabs">
        <Tablist>
          {subNavigation.map((tabItem, index) => (
            <Tab key={index} value={tabItem} is="div" id={tabItem} height={30} isSelected={tabItem === tab}>
              <div onClick={() => changeTab(tabItem)}>{tabItem}</div>
            </Tab>
          ))}
        </Tablist>
      </div>
      {tab === 'P90 Low Case' && renderCapitalInvestmentTable('p90')}
      {tab === 'P50 Med Case' && renderCapitalInvestmentTable('p50')}
      {tab === 'P10 High Case' && renderCapitalInvestmentTable('p10')}
      {tab === 'Failure' && renderCapitalInvestmentTable('failure')}
      {tab === 'Existing Conditions' && renderCapitalInvestmentTable('existing')}
    </div>
  )
}

export default CapitalInvestments;
