import * as React from 'react';
import PropTypes from 'prop-types';

function SummaryViewLink({ tabName }) {
  return (
    <a
      href={`${window.location.origin}/summary/index.html`}
      target="_blank"
      rel="noopener noreferrer"
      className="print-link"
    >
      {tabName}
    </a>
  );
}

SummaryViewLink.propTypes = {
  tabName: PropTypes.string.isRequired,
};

export default SummaryViewLink;
