import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'

import ProjectsTable from './ProjectsTable'
import TooltipIcon from './TooltipIcon'
import {isShowingProjectsModal} from '../actions'

const Loading = ({message}) => (
  <div className="loading-projects">
    <CircularProgress />
    <div style={{marginTop: '1rem'}}>{message}</div>
  </div>
)

const Empty = () => (
  <div className="loading-projects">
    <div style={{marginTop: '1rem'}}>
      No projects exist. Create a new one and save it. Then it should appear in
      this list of available projects.
    </div>
  </div>
)

const Success = ({
  isShowingOnlyMyProjects,
  projectIds,
  setIsShowingOnlyMyProjects,
}) => (
  <>
    <div className="projects-header">
      <TooltipIcon tooltip="The 'Show Only My Projects' checkbox controls the project list contents.  Uncheck to see all projects, from all HEConomics users.  If you open another user's project, a copy will be saved (if you Save) under your username." />
      <p>&nbsp;&nbsp;</p>
      <div className="projects-title"> Available Projects</div>
      <div className="load-projects-header">
        <div>Show Only My Projects: </div>
        <Checkbox
          checked={isShowingOnlyMyProjects}
          onChange={() => setIsShowingOnlyMyProjects(!isShowingOnlyMyProjects)}
          inputProps={{'aria-label': 'primary checkbox'}}
        />
      </div>
    </div>
    <div className="loadProjectsTableContainer">
      <ProjectsTable
        // projects={projects}
        projectIds={projectIds}
        isShowingOnlyMyProjects={isShowingOnlyMyProjects}
      />
    </div>
  </>
)

const LoadProjectModal = ({
  projectIds,
  isLoadingProjectDetails,
  loadingProjectDetailsMessage,
}) => {
  const [isShowingOnlyMyProjects, setIsShowingOnlyMyProjects] = useState(true)

  const {status} = useSelector(
    state => ({
      status: state.status,
      // projectIds: state.projectIds,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(isShowingProjectsModal(false))
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={status.isShowingProjectsModal}
    >
      {isLoadingProjectDetails ? (
        <Loading
          message={loadingProjectDetailsMessage || 'Loading project details...'}
        />
      ) : projectIds === null || projectIds === undefined ? (
        <Loading message="Loading project list..." />
      ) : projectIds?.length === 0 ? (
        <Empty />
      ) : (
        <Success
          isShowingOnlyMyProjects={isShowingOnlyMyProjects}
          projectIds={projectIds}
          setIsShowingOnlyMyProjects={setIsShowingOnlyMyProjects}
        />
      )}
    </Dialog>
  )
}

export default LoadProjectModal
