import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { updateTaxes } from '../actions';

const XLSX = require('xlsx');

const TaxesAdminButtons = () => {
  const [inputKey, setInputKey] = React.useState(1);

  const { taxes } = useSelector((state) => ({
    taxes: state.taxes,
  }),
  shallowEqual);

  const dispatch = useDispatch();

  const downloadTaxesSheet = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(taxes, { header: ['State', 'gasSevTaxRatePercent', 'gasProdTaxRate', 'oilSevTaxRatePercent', 'oilProdTaxRate', 'nglSevTaxRatePercent', 'nglProdTaxRate'] });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Taxes');
    XLSX.writeFile(workbook, `HEConomics-Taxes-${moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  // Changes the key on the input to force a re-render on upload.
  // Allows same file name to be used with updated values.
  // Otherwise, the file input onChange handler would not fire if file name is same
  const resetFileInput = () => {
    setInputKey(Math.random().toString(36));
  };

  // Receive file upload and update internal application state (e.g. Redux store) and back end
  const uploadTaxesSheet = (e) => {
    const { files } = e.target;
    dispatch(updateTaxes(files[0]));
  };

  return (
    <div>
      <input
        aria-label="upload-sheet"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        key={inputKey}
        onChange={(e) => uploadTaxesSheet(e)}
      />
      <label style={{ height: '100%' }} htmlFor="raised-button-file">
        <LowerCaseButton
          aria-label="upload-taxes"
          variant="contained"
          color="primary"
          style={{ padding: '0.2rem 1rem' }}
          onClick={resetFileInput}
          component="span"
        >
          Upload & Overwrite
        </LowerCaseButton>
      </label>
      <LowerCaseButton
        aria-label="download"
        variant="contained"
        color="primary"
        style={{ padding: '0.2rem 1rem', margin: '0 0 0 1rem' }}
        onClick={downloadTaxesSheet}
      >
        Download
      </LowerCaseButton>
    </div>
  );
};

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'rgb(85, 108, 120)',
    minWidth: '100px',
    minHeight: '40px',
  },
})(Button);

export default TaxesAdminButtons;
