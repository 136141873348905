import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  numberFormatter,
  currencyFormatter,
  getMaxLengthIndexArray,
  CASE_HEADERS,
} from '../utils';

const DataTable = ({ attribute, type, decimals }) => {

    // Use useSelector Hook to pull from Redux store
    const { results } = useSelector(state => ({
      results: state.results,
    }), shallowEqual);

    const { p90, p50, p10, mean, failure, risked, existing, incrementalRisked } = results;
    let formattedData;

    if(type === "number") {
      formattedData = {
        p90: p90?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        p50: p50?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        p10: p10?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        mean: mean?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        failure: failure?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        risked: risked?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        existing: existing?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
        incrementalRisked: incrementalRisked?.cashflow?.[attribute]?.map(item => numberFormatter(Number(item), decimals || 0)),
      };
    }
    else if (type === "currency") {
      formattedData = {
        p90: p90?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        p50: p50?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        p10: p10?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        mean: mean?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        failure: failure?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        risked: risked?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        existing: existing?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
        incrementalRisked: incrementalRisked?.cashflow?.[attribute]?.map(item => currencyFormatter(item, decimals || 0)),
      };
    }

    const maxLengthIndexArray = getMaxLengthIndexArray(formattedData);

    return (
      <div className="data-table-container">
        <table>
          <tbody>
            {//formattedData?.p90?.map((item, index) => (
             maxLengthIndexArray?.map((item, index) => (
              <tr>
                <td style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  {index + 1}
                </td>
                {CASE_HEADERS.map((caseHeader) => (
                  <td>
                    {formattedData?.[caseHeader]?.[index]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
}

export default DataTable;
