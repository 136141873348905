/*
    Name: CASES Component
    Description: This component is the holder for all of the standard case input data, shown upon first load once authentication is verified
*/
import * as React from 'react'
import {Table, Select} from 'evergreen-ui'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import RowTitle from './RowTitle'
import DataRow from './DataRow'
import SectionHeader from './SectionHeader'
import CalculatedRow from './CalculatedRow'
import CustomForecast from './CustomForecast'
import TooltipIcon from './TooltipIcon'
import CustomOPEXModal from './CustomOPEXModal'

import {updateValues, showCustomForecast} from '../actions'
import {
  numberFormatter,
  CASE_HEADERS,
  CASE_HEADERS_LABELS,
  CASE_ROW_HEIGHT,
  PRODUCTION_FORECAST_METHODS,
  CaseNameType,
  ProductionForecastMethodType,
} from '../utils'
import {resetUnusedCaseInput} from '../actions/resetUnusedCaseInput'

const Cases = () => {
  // Use useSelector Hook to pull from Redux store
  const {cases, subPage, results, project} = useSelector(
    state => ({
      subPage: state.navigation.subPage,
      results: state.results,
      project: state.projectInputs,
      cases: state.cases,
    }),
    shallowEqual,
  )

  const EmptyCell = ({index}) => (
    <td className="row-cell" key={index}>
      <div className="empty-cell">{`-`}</div>
    </td>
  )

  const dispatch = useDispatch()

  const addCustomForecast = caseItem => {
    dispatch(showCustomForecast(true, caseItem))
  }

  const handleProductionForecastMethodChange = (
    caseName: CaseNameType,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const {value} = event.target
    dispatch(updateValues(caseName, 'prodForecastMethod', value))

    dispatch(
      resetUnusedCaseInput(caseName, value as ProductionForecastMethodType),
    )

    if (value === 'Custom Major Phase Forecast') {
      dispatch(showCustomForecast(true, caseName))
    }
  }

  return (
    <>
      <CustomOPEXModal/>
      <CustomForecast />
      <table className="caseData-table">
        <thead height={48}>
          <tr>
            <th
              style={{
                color: 'white',
                fontSize: '20px',
                fontWeight: '600',
                justifyContent: 'flex-start',
                paddingLeft: '10px',
              }}
              className="row-title"
            >
              Case Input
            </th>
            {CASE_HEADERS_LABELS.map((item, index) => {
              return (
                <th
                  key={index}
                  style={{width: '100%', textAlign: 'center', padding: '5px'}}
                >
                  {item}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          <tr height={CASE_ROW_HEIGHT}>
            <RowTitle title="Case Weight Factors (%)">
              <TooltipIcon tooltip="The low, median and high cases are weighted via a method called Swanson's Rule.  Low is weighted 30%, median is weighted 40%, and high is weighted 30%.  These weights are all proportionally reduced by the percentage change of failure.  Swanson's Rule provides a quick method to determine the mean of a lognormally distributed set of potential outcomes." />
            </RowTitle>
            {[0.3, 0.4, 0.3, 1].map((item, index) => (
              <td key={index}>
                <div className="case-input">
                  {Number(project.successPs)
                    ? `${numberFormatter(project.successPs * item, 1)} %`
                    : '-'}
                </div>
              </td>
            ))}
            <td>
              {Number(project.successPs)
                ? `${numberFormatter(100 - project.successPs, 1)} %`
                : '-'}
            </td>
            {[1, 1, 1].map((item, index) => (
              <td key={index}>
                <div className="case-input">
                  {Number(project.successPs)
                    ? `${numberFormatter(100 * item, 1)} %`
                    : '-'}
                </div>
              </td>
            ))}
          </tr>
          <SectionHeader title="Investment" />
          <DataRow
            cases={cases}
            dataName="capitalGross"
            rowTitle="Gross Month 1 Investment ($)"
            decimals={0}
          >
            <TooltipIcon tooltip="Future month investments can be added via the Additional Investments tab." />
          </DataRow>
          <SectionHeader title="Production" />
          <tr height={30}>
            <RowTitle title="Prod Forecast Method">
              <TooltipIcon tooltip="Various production forecasting methods are available via the drop-down menu." />
            </RowTitle>
            {CASE_HEADERS.map((caseName, index) => {
              if (['mean', 'risked', 'incrementalRisked'].includes(caseName)) {
                return <EmptyCell key={index} />
              }
              return (
                <td className="row-cell" key={index}>
                  <Select
                    fontSize={10}
                    height={20}
                    value={cases[caseName].prodForecastMethod}
                    onChange={e =>
                      handleProductionForecastMethodChange(caseName, e)
                    }
                    name="prodForecastMethod"
                  >
                    {PRODUCTION_FORECAST_METHODS.map(
                      (prodForecastMethod, index) => (
                        <option
                          key={index}
                          fontSize={10}
                          name="prodForecastMethod"
                          placeholder="-"
                          className="table-input"
                          value={prodForecastMethod}
                        >
                          {prodForecastMethod}
                        </option>
                      ),
                    )}
                  </Select>
                </td>
              )
            })}
          </tr>
          {Object.keys(cases).find(
            caseItem =>
              cases[caseItem].prodForecastMethod ===
              'Custom Major Phase Forecast',
          ) && (
            <tr className="table-row">
              <RowTitle title="Custom Forecast" />
              {CASE_HEADERS.map((caseName, index) => {
                if (
                  ['mean', 'risked', 'incrementalRisked'].includes(caseName) ||
                  cases[caseName].prodForecastMethod !==
                    'Custom Major Phase Forecast'
                ) {
                  return <EmptyCell key={index} />
                }
                return (
                  <td key={index}>
                    <button
                      type="button"
                      style={{fontSize: '14px', textAlign: 'center'}}
                      className="clear-link"
                      onClick={() => addCustomForecast(caseName)}
                    >
                      Edit
                    </button>
                  </td>
                )
              })}
            </tr>
          )}
          {project.major === 'Oil' && (
            <DataRow
              dataName="initialDailyRate"
              rowTitle="Gross Initial Oil Rate (BOPD)"
              decimals={0}
            >
              {' '}
              <TooltipIcon tooltip="The initial rate will equal the average rate for the first month of production." />{' '}
            </DataRow>
          )}
          {project.major === 'Gas' && (
            <DataRow
              dataName="initialDailyRate"
              rowTitle="Gross Initial Gas Rate (MCF)"
              decimals={0}
            >
              {' '}
              <TooltipIcon tooltip="The initial rate will equal the average rate for the first month of production." />{' '}
            </DataRow>
          )}
          {project.major === 'Oil' && (
            <DataRow
              dataName="finalDailyRate"
              rowTitle="Gross Final Oil Rate (BOPD)"
              decimals={0}
            />
          )}
          {project.major === 'Gas' && (
            <DataRow
              dataName="finalDailyRate"
              rowTitle="Gross Final Gas Rate (MCF)"
              decimals={0}
            />
          )}
          <DataRow
            cases={cases}
            dataName="annualEffectiveDeclineRatePercent"
            rowTitle="Initial Decline Rate (%/Yr)"
            decimals={1}
          >
            <TooltipIcon
              tooltip={
                <div>
                  <p>
                    Annual effective decline rate is expected. This equals:
                    (initial rate - rate @ one year) / initial rate.{' '}
                  </p>
                  <p>
                    For hyperbolic forecasts, the <b>Secant Method</b> (not the
                    tangent method) is applied, in which the initial decline is
                    calculated via the formula just noted, using the initial
                    rate and the rate @ one year.
                  </p>
                </div>
              }
            />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="hyperbolicExponent"
            rowTitle="Hyperbolic Exponent"
            decimals={2}
          >
            <TooltipIcon
              tooltip={
                <div>
                  <p>
                    The hyperbolic exponent, also known as the b factor,
                    determines the amount of curvature (or bending up) in the
                    forecast.
                  </p>
                  <p>
                    This number is typically greater than zero and less than
                    one. In some stituations (often very low permeability
                    formations), b factor values greater than one are accepted.
                    Numbers greater than three or four are suspect, because the
                    curve may become unreasonably flat.
                  </p>
                  <p>
                    It is common practice to create an initial hyperbolic
                    decline that reverts to exponential decline at a certain
                    limiting decline rate. You can create such a forecast in
                    HEConomics by selecting the Hyperbolic to Exponential option
                    in the Prod Forecast Method dropdown menu.
                  </p>
                  <p>
                    Note that when defining the initial decline rate for
                    hyperbolic forecasts in HEConomics, the <b>Secant Method</b>{' '}
                    (not the tangent method) is applied. The expected initial
                    annual effective decline rate equals: (initial rate - rate @
                    one year) / initial rate
                  </p>
                </div>
              }
            />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="terminalAnnualEffectiveDeclineRatePercent"
            rowTitle="Terminal Decline Rate (%/Yr)"
            decimals={1}
          >
            <TooltipIcon
              tooltip={
                <div>
                  <p>
                    The terminal decline rate is used in the Hyperbolic to
                    Exponential production forecast method.
                  </p>
                  <p>
                    When the instantaneous decline rate in a hyperbolic forecast
                    reaches the terminal decline rate, the forecast shifts to an
                    exponential decline, and the decline rate then remains
                    constant at the terminal decline rate value.
                  </p>
                  <p>
                    The terminal decline rate should be a positive value that is
                    less than the initial decline rate.
                  </p>
                </div>
              }
            />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="maxLifeYears"
            rowTitle="Max Life (Years)"
            decimals={0}
          >
            <TooltipIcon
              tooltip={
                <div>
                  <p>
                    Production forecasts end at the minimum of either the
                    economic limit, the app default of 50 years, or the value
                    that you enter in this row.
                  </p>
                  <p>
                    If you leave this input blank, then the forecast will end at
                    the economic limit or the app default of 50 years, whichever
                    comes first.
                  </p>
                </div>
              }
            />
          </DataRow>
          {project.major === 'Oil' && (
            <DataRow
              cases={cases}
              dataName="reserves"
              rowTitle="Gross Oil Reserves (MBO)"
              decimals={3}
            />
          )}
          {project.major === 'Gas' && (
            <DataRow
              cases={cases}
              dataName="reserves"
              rowTitle="Gross Gas Reserves (BCF)"
              decimals={3}
            />
          )}
          {project.major === 'Oil' && (
            <DataRow
              cases={cases}
              dataName="gasOilRatio"
              rowTitle="Producing GOR (MCF/BO)"
              decimals={1}
            />
          )}
          {project.major === 'Gas' && (
            <DataRow
              cases={cases}
              dataName="condensateYield"
              rowTitle="Condensate Yield (BO/MMCF)"
              decimals={1}
            />
          )}
          <DataRow
            cases={cases}
            dataName="nglYield"
            rowTitle="NGL Yield (BO/MMCF)"
            decimals={1}
          >
            <TooltipIcon tooltip="Natural Gas Liquid (NGL) yield is equal to gross NGL sales volume divided by gross gas production (pre-shrink) volume." />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="delayStart"
            rowTitle="Delay Start Date (Months)"
            decimals={0}
          >
            <TooltipIcon
              tooltip={
                <div>
                  <p>Shift the start of production and capital.</p>
                  <p>Zero (no shifting) is recommended and preferred.</p>
                  <p>
                    If you do add a delay, the same value probably should be
                    added to all input cases, including the failure and existing
                    cases.
                  </p>
                  <p>
                    NOTE: By default, production always begins one month after
                    the Month 1 Investment.
                  </p>
                </div>
              }
            />
          </DataRow>
          <SectionHeader title="OPEX" customAction={true} />
          <DataRow
            cases={cases}
            dataName="opexVariableGasGross"
            rowTitle="Gross Variable Gas OPEX($/MCF)"
            decimals={2}
          >
            <TooltipIcon tooltip="Gross gas sales volume (after shrink) is multiplied by this $/MCF value to obtain the total variable gas OPEX by month." />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="opexVariableOilGross"
            rowTitle="Gross Variable Oil OPEX ($/BO)"
            decimals={2}
          >
            <TooltipIcon tooltip="Gross oil sales volume is multiplied by this $/BO value to obtain the total variable oil OPEX by month." />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="opexPerWellGross"
            rowTitle="Gross Fixed Well Cost ($/mo)"
            decimals={0}
          >
            <TooltipIcon tooltip="Fixed monthly cost remain constant over the entire economic life." />
          </DataRow>
          <DataRow
            cases={cases}
            dataName="overheadGross"
            rowTitle="Gross Overhead ($/mo)"
            decimals={0}
          >
            <TooltipIcon tooltip="Gross values are expected.  The gross overhead amount is multiplied by the working interest to obtain a net value." />
          </DataRow>
          {/* KTE, 7/9/2020:  Removing Expense AFE numbers from the tool;
          they should be in the fixed well cost.
          <DataRow cases={cases} dataName="opexYearlyExpenseAFEGross"
          rowTitle="Expense AFE Costs ($/mo)" decimals={0} />
          */}
          <CalculatedRow
            rowTitle="Gross Month 1 Total OPEX ($/mo)"
            results={results}
            subObject="economicMetrics"
            dataName="initialOpexTotalGross"
            decimals={0}
          >
            <TooltipIcon tooltip="Includes fixed expenses, variable expenses and overhead; but NOT taxes." />
          </CalculatedRow>
          <SectionHeader title="Calculated Results: Investment" />
          <CalculatedRow
            rowTitle="Gross Investment ($)"
            results={results}
            subObject="oneline"
            dataName="capitalGross"
            decimals={0}
          />
          <CalculatedRow
            rowTitle="Net Investment ($)"
            results={results}
            subObject="oneline"
            dataName="capitalNet"
            decimals={0}
          />
          <SectionHeader title="Calculated Results: Production" />
          <CalculatedRow
            rowTitle="Gross Oil Reserves (MBO)"
            results={results}
            subObject="economicMetrics"
            dataName="oilGrossMbo"
            decimals={1}
          />
          <CalculatedRow
            rowTitle="Gross Gas Reserves (BCF)"
            results={results}
            subObject="economicMetrics"
            dataName="gasGrossBcf"
            decimals={3}
          />
          <CalculatedRow
            rowTitle="Gross Oil Equivalent (MBOE)"
            results={results}
            subObject="economicMetrics"
            dataName="boeGrossMboe"
            decimals={1}
          />
          <CalculatedRow
            rowTitle="Gross Initial Oil Rate (BOPD)"
            results={results}
            subObject="economicMetrics"
            dataName="initialDailyOilGross"
            decimals={1}
          />
          <CalculatedRow
            rowTitle="Gross Initial Gas Rate (MCFD)"
            results={results}
            subObject="economicMetrics"
            dataName="initialDailyGasGross"
            decimals={0}
          />
          <CalculatedRow
            rowTitle="Gross Final Oil Rate (BOPD)"
            results={results}
            subObject="economicMetrics"
            dataName="finalDailyOilGross"
          />
          <CalculatedRow
            rowTitle="Gross Final Gas Rate (MCFD)"
            results={results}
            subObject="economicMetrics"
            dataName="finalDailyGasGross"
          />
          <CalculatedRow
            rowTitle="Net Oil Reserves (MBO)"
            results={results}
            subObject="economicMetrics"
            dataName="oilNetMbo"
            decimals={0}
          />
          <CalculatedRow
            rowTitle="Net Gas Reserves (BCF)"
            results={results}
            subObject="economicMetrics"
            dataName="gasNetBcf"
            decimals={3}
          />
          <CalculatedRow
            rowTitle="Net Oil Equivalent (MBOE)"
            results={results}
            subObject="economicMetrics"
            dataName="boeNetMboe"
            decimals={0}
          />
          <CalculatedRow
            rowTitle="Net Initial BOE Rate (BOEPD)"
            results={results}
            subObject="economicMetrics"
            dataName="initialDailyBoeNet"
            decimals={0}
          />
          <SectionHeader title="Calculated Results: Cash Flow" />
          <CalculatedRow
            rowTitle="Initial Net Cash Flow ($/mo)"
            results={results}
            subObject="economicMetrics"
            dataName="initialNetOperatingIncome"
            decimals={0}
          />
          <SectionHeader title="Calculated Results: Economic Metrics" />
          <CalculatedRow
            rowTitle="Payout (Relative Months)"
            results={results}
            subObject="economicMetrics"
            dataName="payout"
          />
          <CalculatedRow
            rowTitle="PVR-10% ($/$)"
            results={results}
            subObject="economicMetrics"
            dataName="pvr10"
            decimals={2}
          />
          <CalculatedRow
            rowTitle="PVR-15% ($/$)"
            results={results}
            subObject="economicMetrics"
            dataName="pvr15"
            decimals={2}
          />
          <CalculatedRow
            rowTitle="Net PV-10% ($)"
            results={results}
            subObject="oneline"
            dataName="presentValue10"
            decimals={0}
          />
          <CalculatedRow
            rowTitle="Net PV-15% ($)"
            results={results}
            subObject="oneline"
            dataName="presentValue15"
            decimals={0}
          />
          <CalculatedRow
            rowTitle="IRR (%)"
            results={results}
            subObject="economicMetrics"
            dataName="ror"
          />
          <CalculatedRow
            rowTitle="Dev. Cost ($/BOE)"
            results={results}
            subObject="economicMetrics"
            dataName="dollarsPerBOE"
            decimals={2}
          />
          <CalculatedRow
            rowTitle="Economic Life (Years)"
            results={results}
            subObject="economicMetrics"
            dataName="economicLifeYears"
            decimals={1}
          />
        </tbody>
      </table>
    </>
  )
}

export default Cases
