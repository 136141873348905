import * as React from 'react'
import {Table} from 'evergreen-ui'
import {connect} from 'react-redux'

import RowTitle from './RowTitle'
import {updateValues} from '../actions'
import {
  numberFormatter,
  CASE_HEADERS,
  CASE_ROW_HEIGHT,
  CASE_ROW_INPUT_HEIGHT,
  CURRENCY_FIELDS,
} from '../utils'
import {formatHEConomicsNumber} from '../utils/formatHEConomicsNumber'

class DataRow extends React.Component {
  handleChange = (caseName, event) => {
    let {name, value} = event.target

    if (name === 'capitalGross') {
      let newArray = [...this.props.cases[caseName].capitalGross]
      let filterCommaValue = value.replace(/,/g, '')
      if (isNaN(filterCommaValue)) {
        newArray[0] = filterCommaValue.toString()
      } else {
        newArray[0] = numberFormatter(Number(filterCommaValue)).toString()
      }

      this.props.updateValues(caseName, name, newArray)
    } else {
      this.props.updateValues(caseName, name, value.toString())
    }
  }

  handleFocus = event => event.target.select()

  handleKeyDown = (caseItem, attribute, {key, target}) => {
    console.log(caseItem)
    if (key === 'Enter') {
      target.blur()
      if (caseItem === 'p90') {
        this.refs[`p50${attribute}`].focus()
      } else if (caseItem === 'p50') {
        this.refs[`p10${attribute}`].focus()
      } else if (caseItem === 'p10') {
        this.refs[`failure${attribute}`].focus()
      } else if (caseItem === 'failure') {
        this.refs[`existing${attribute}`].focus()
      }
    }
  }

  isDisabledInput = (prodForecastMethod, dataName) =>
    (prodForecastMethod === 'Exponential: Calc Decline and Life' &&
      [
        'annualEffectiveDeclineRatePercent',
        'maxLifeYears',
        'hyperbolicExponent',
        'terminalAnnualEffectiveDeclineRatePercent',
      ].includes(dataName)) ||
    (prodForecastMethod === 'Hyperbolic: Calc Reserves and Final Rate' &&
      [
        'finalDailyRate',
        'reserves',
        'terminalAnnualEffectiveDeclineRatePercent',
      ].includes(dataName)) ||
    (prodForecastMethod ===
      'Hyperbolic to Exponential: Calc Reserves and Final Rate' &&
      ['finalDailyRate', 'reserves'].includes(dataName)) ||
    (prodForecastMethod === 'Exponential: Calc Reserves and Final Rate' &&
      [
        'finalDailyRate',
        'reserves',
        'hyperbolicExponent',
        'terminalAnnualEffectiveDeclineRatePercent',
      ].includes(dataName)) ||
    (prodForecastMethod === 'Custom Major Phase Forecast' &&
      [
        'initialDailyRate',
        'annualEffectiveDeclineRatePercent',
        'hyperbolicExponent',
        'finalDailyRate',
        'reserves',
        'maxLifeYears',
        'terminalAnnualEffectiveDeclineRatePercent',
      ].includes(dataName))

  render() {
    const {
      rowTitle,
      cases,
      results,
      decimals,
      dataName,
      errors,
      children,
    } = this.props

    return (
      <tr className="table-row" height={CASE_ROW_HEIGHT}>
        <RowTitle title={rowTitle}>{children}</RowTitle>
        {CASE_HEADERS.map((caseName, index) => (
          <td key={index}>
            {['mean', 'risked', 'incrementalRisked'].includes(caseName) ? (
              <div className="case-input">
                {formatHEConomicsNumber(
                  CURRENCY_FIELDS.includes(dataName),
                  decimals,
                  results?.[caseName]?.economicMetrics?.[dataName],
                )}
              </div>
            ) : this.isDisabledInput(
                cases[caseName].prodForecastMethod,
                dataName,
              ) ? (
              <input
                aria-label={`${caseName}-${dataName}`}
                disabled
                fontSize={12}
                height={CASE_ROW_INPUT_HEIGHT}
                name={dataName}
                ref={`${caseName}${dataName}`}
                placeholder="-"
                className="table-input case-input"
                style={{backgroundColor: '#dfe6e9'}}
                value={results[caseName]?.economicMetrics[dataName] || '-'}
                onChange={e => this.handleChange(caseName, e)}
                onFocus={this.handleFocus}
                onKeyDown={event =>
                  this.handleKeyDown(caseName, dataName, event)
                }
              />
            ) : dataName === 'capitalGross' ? (
              <input
                aria-label={`${caseName}-${dataName}`}
                fontSize={12}
                height={CASE_ROW_INPUT_HEIGHT}
                name={dataName}
                ref={`${caseName}${dataName}`}
                placeholder="-"
                className={
                  errors.find(
                    errorItem =>
                      errorItem.errorKey === dataName &&
                      errorItem.errorCase === caseName,
                  )
                    ? 'invalid-input yellow-input'
                    : 'table-input case-input'
                }
                value={
                  cases[caseName]?.[dataName] !== undefined &&
                  cases[caseName]?.[dataName][0] !== 0
                    ? cases[caseName]?.[dataName][0]
                    : ''
                }
                onChange={e => this.handleChange(caseName, e)}
                onFocus={this.handleFocus}
                onKeyDown={event =>
                  this.handleKeyDown(caseName, dataName, event)
                }
              />
            ) : [
                'opexVariableGasGross',
                'opexVariableOilGross',
                'opexPerWellGross',
                'overheadGross',
                'nglYield',
              ].includes(dataName) ? (
              <input
                aria-label={`${caseName}-${dataName}`}
                fontSize={12}
                height={CASE_ROW_INPUT_HEIGHT}
                name={dataName}
                ref={`${caseName}${dataName}`}
                placeholder="-"
                className={
                  errors.find(
                    errorItem =>
                      errorItem.errorKey === dataName &&
                      errorItem.errorCase === caseName,
                  )
                    ? 'invalid-input field-input'
                    : 'field-input case-input'
                }
                value={
                  cases[caseName]?.[dataName] !== undefined
                    ? Array.isArray(cases[caseName]?.[dataName]) ? cases[caseName]?.[dataName][0] + "..." + cases[caseName]?.[dataName][cases[caseName]?.[dataName].length - 1] : cases[caseName]?.[dataName]
                    : ''
                }
                onChange={e => this.handleChange(caseName, e)}
                onFocus={this.handleFocus}
                onKeyDown={event =>
                  this.handleKeyDown(caseName, dataName, event)
                }
              />
            ) : (
              <input
                aria-label={`${caseName}-${dataName}`}
                fontSize={16}
                height={CASE_ROW_INPUT_HEIGHT}
                name={dataName}
                ref={`${caseName}${dataName}`}
                placeholder="-"
                className={
                  errors.find(
                    errorItem =>
                      errorItem.errorKey === dataName &&
                      errorItem.errorCase === caseName,
                  )
                    ? 'invalid-input yellow-input'
                    : 'table-input case-input'
                }
                value={
                  cases[caseName]?.[dataName] !== undefined
                    ? cases[caseName]?.[dataName]
                    : ''
                }
                onChange={e => this.handleChange(caseName, e)}
                onFocus={this.handleFocus}
                onKeyDown={event =>
                  this.handleKeyDown(caseName, dataName, event)
                }
              />
            )}
          </td>
        ))}
      </tr>
    )
  }
}

const mapStateToProps = state => {
  return {
    cases: state.cases,
    results: state.results,
    errors: state.inputErrors,
  }
}

const mapDispatchToProps = {updateValues}

export default connect(mapStateToProps, mapDispatchToProps)(DataRow)
