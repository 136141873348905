/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Component imports */
import MonthlyDataView from './MonthlyDataView';

import {
  createPlotDataObjectArrayAllCases,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';

import '../styles/graphs.css';

const Investments = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, results, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    results: state.results,
    status: state.status,
  }), shallowEqual);

  const capitalGrossPlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalGross');
  const capitalGrossCumPlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalGrossCum');
  const capitalNetPlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalNet');
  const capitalNetCumPlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalNetCum');
  const capitalNetPV10PlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalNetDiscounted10');
  const capitalNetPV15PlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalNetDiscounted15');

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      {subPage === 'Gross Investments' && (
        <MonthlyDataView
          attribute="capitalGross"
          dataArray={capitalGrossPlotAllCases}
          decimals={0}
          title="Gross Investments"
          subTitle="Gross $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Gross Investments (Gross $ per Month)"
        />
      )}
      {subPage === 'Cum Gross Investments' && (
        <MonthlyDataView
          attribute="capitalGrossCum"
          dataArray={capitalGrossCumPlotAllCases}
          decimals={0}
          title="Cum Gross Investments"
          subTitle="Gross $ vs Time"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Cum Gross Investments (Gross $ vs Time)"
        />
      )}
      {subPage === 'Net Investments' && (
        <MonthlyDataView
          attribute="capitalNet"
          dataArray={capitalNetPlotAllCases}
          decimals={0}
          title="Net Investments"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Investments (Net $ per Month)"
        />
      )}
      {subPage === 'Cum Net Investments' && (
        <MonthlyDataView
          attribute="capitalNetCum"
          dataArray={capitalNetCumPlotAllCases}
          decimals={0}
          title="Cum Net Investments"
          subTitle="Net $ vs Time"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Cum Net Investments (Net $ vs Time)"
        />
      )}
      {subPage === 'PV-10 Net Investments' && (
        <MonthlyDataView
          attribute="capitalNetDiscounted10"
          dataArray={capitalNetPV10PlotAllCases}
          decimals={0}
          title="PV-10 Net Investments"
          subTitle="Discounted Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="PV-10 Net Investments (Discounted Net $ per Month)"
        />
      )}
      {subPage === 'PV-15 Net Investments' && (
        <MonthlyDataView
          attribute="capitalNetDiscounted15"
          dataArray={capitalNetPV15PlotAllCases}
          decimals={0}
          title="PV-15 Net Investments"
          subTitle="Discounted Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="PV-15 Net Investments (Discounted Net $ per Month)"
        />
      )}
    </div>
  );
};

export default Investments;
