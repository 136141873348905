/* 
  Name: Prices Table
  Purpose: Show admin users the full price forecast values available to all users.
  Price forecasts are updated and viewed one at a time in the current configuration.
  Price forecasts may also be deleted in this view, but the delete functionality is
  managed in the PricesAdminButtons component rather than here.
*/

/* Third party NPM packages */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Table } from 'evergreen-ui';

const PricesTable = () => {
  // Use useSelector Hook to pull from Redux store
  const { prices, status } = useSelector((state) => ({
    prices: state.prices,
    status: state.status,
  }), shallowEqual);

  const priceDeck = prices.find(item => status.priceFilter === item.PriceName);

  return (
    <Table id="admin-tables" border backgroundColor="white">
      <Table.Head height={48}>
          {['Month', 'Gas', 'Oil'].map((item, index) => (
            <Table.TextHeaderCell key={index}>
              {item}
            </Table.TextHeaderCell>
          ))}
      </Table.Head>
      <Table.VirtualBody className="admin-table-body" height={624}>
        {priceDeck?.Oil.map((item, index) => (
            <Table.Row height={25} key={index} isSelectable>
              <Table.TextCell isNumber>{index + 1}</Table.TextCell>
              <Table.TextCell isNumber>{priceDeck.Gas[index]}</Table.TextCell>
              <Table.TextCell isNumber>{priceDeck.Oil[index]}</Table.TextCell>
            </Table.Row>
          )
        )}
      </Table.VirtualBody>
    </Table>
  )
}

export default PricesTable;
