/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Component imports */
import PlotLineGraph from './PlotLineGraph';
import DataTable from './DataTable';
import DataTableHeader from './DataTableHeader';

import {
  createPlotDataObjectArrayAllCases,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';

import '../styles/graphs.css';

const Reserves = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, results, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    results: state.results,
    status: state.status,
  }), shallowEqual);

  const grossOilPlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'oilGrossDaily');
  const grossGasPlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'gasGrossDaily');
  const grossNglPlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'nglGrossDaily');
  const cumGrossOilPlotAllCases = createPlotDataObjectArrayAllCases(results, 'oilGrossCum');
  const cumGrossGasPlotAllCases = createPlotDataObjectArrayAllCases(results, 'gasGrossCum');
  const cumGrossNglPlotAllCases = createPlotDataObjectArrayAllCases(results, 'nglGrossCum');
  const netOilPlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'oilNetDaily');
  const netGasPlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'gasNetDaily');
  const netNglPlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'nglNetDaily');
  const netBoePlotDailyAllCases = createPlotDataObjectArrayAllCases(results, 'boeNetDaily');
  const cumNetOilPlotAllCases = createPlotDataObjectArrayAllCases(results, 'oilNetCum');
  const cumNetGasPlotAllCases = createPlotDataObjectArrayAllCases(results, 'gasNetCum');
  const cumNetNglPlotAllCases = createPlotDataObjectArrayAllCases(results, 'nglNetCum');
  const cumNetBoePlotAllCases = createPlotDataObjectArrayAllCases(results, 'boeNetCum');

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      { subPage === 'Gross Oil' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={grossOilPlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Gross BOPD)"
            yAxisType="log"
            title="Oil Daily Production Rate"
            subTitle="Gross BOPD vs Time"
          />
        </div>
      )}
      { subPage === 'Gross Gas' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={grossGasPlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Gross MCFD)"
            yAxisType="log"
            title="Gas Daily Production Rate"
            subTitle="Gross MCFD vs Time"
          />
        </div>
      )}
      { subPage === 'Gross NGL' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={grossNglPlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Gross Bbls)"
            yAxisType="log"
            title="NGL Daily Production Rate"
            subTitle="Gross Bbls vs Time"
          />
        </div>
      )}
      { subPage === 'Cum Gross Oil' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumGrossOilPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Gross Oil (BO)"
            yAxisType="cartesian"
            title="Cum Gross Oil"
            subTitle="Gross BO vs Time"
          />
        </div>
      )}
      { subPage === 'Cum Gross Gas' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumGrossGasPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Gross Gas (MCF)"
            yAxisType="cartesian"
            title="Cum Gross Gas"
            subTitle="Gross MCF vs Time"
          />
        </div>
      )}
      { subPage === 'Cum Gross NGL' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumGrossNglPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Gross NGL (Bbls)"
            yAxisType="cartesian"
            title="Cum Gross NGL"
            subTitle="Gross Bbls vs Time"
          />
        </div>
      )}
      { subPage === 'Net Oil' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={netOilPlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Net BOPD)"
            yAxisType="log"
            title="Oil Daily Production Rate"
            subTitle="Net BOPD vs Time"
          />
        </div>
      )}
      { subPage === 'Net Gas' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={netGasPlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Net MCFD)"
            yAxisType="log"
            title="Gas Daily Production Rate"
            subTitle="Net MCFD vs Time"
          />
        </div>
      )}
      { subPage === 'Net NGL' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={netNglPlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Net Bbls)"
            yAxisType="log"
            title="NGL Daily Production Rate"
            subTitle="Net Bbls vs Time"
          />
        </div>
      )}
      { subPage === 'Net BOE' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={netBoePlotDailyAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Daily Rate (Net BOE per month)"
            yAxisType="log"
            title="BOE Daily Production Rate"
            subTitle="Net BOE per month"
          />
        </div>
      )}
      { subPage === 'Cum Net Oil' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumNetOilPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Net Oil (BO)"
            yAxisType="cartesian"
            title="Cum Net Oil"
            subTitle="Net BO vs Time"
          />
        </div>
      )}
      { subPage === 'Cum Net Gas' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumNetGasPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Net Gas (MCF)"
            yAxisType="cartesian"
            title="Cum Net Gas"
            subTitle="Net MCF vs Time"
          />
        </div>
      )}
      { subPage === 'Cum Net NGL' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumNetNglPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Net NGL (Bbls)"
            yAxisType="cartesian"
            title="Cum Net NGL"
            subTitle="Net Bbls vs Time"
          />
        </div>
      )}
      { subPage === 'Cum Net BOE' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={cumNetBoePlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Cum Net BOE (Net BOE vs Time)"
            yAxisType="cartesian"
            title="Cum Net BOE"
            subTitle="Net BOE vs Time"
          />
        </div>
      )}
      {subPage === 'Gross Oil' && (
        <div className="data-table-title">
          Oil Daily Production Rate (Gross BOPD)
        </div>
      )}
      {subPage === 'Gross Gas' && (
        <div className="data-table-title">
          Gas Daily Production Rate (Gross MCFD)
        </div>
      )}
      {subPage === 'Gross NGL' && (
        <div className="data-table-title">
          NGL Daily Production Rate (Gross Bbls)
        </div>
      )}
      {subPage === 'Cum Gross Oil' && (
        <div className="data-table-title">
          Cum Gross Oil (BO)
        </div>
      )}
      {subPage === 'Cum Gross Gas' && (
        <div className="data-table-title">
          Cum Gross Gas (MCF)
        </div>
      )}
      {subPage === 'Cum Gross NGL' && (
        <div className="data-table-title">
          Cum Gross NGL (Gross Bbls)
        </div>
      )}
      {subPage === 'Net Oil' && (
        <div className="data-table-title">
          Oil Daily Production Rate (Net BOPD)
        </div>
      )}
      {subPage === 'Net Gas' && (
        <div className="data-table-title">
          Gas Daily Production Rate (Net MCFD)
        </div>
      )}
      {subPage === 'Net NGL' && (
        <div className="data-table-title">
          NGL Daily Production Rate (Net Bbls)
        </div>
      )}
      {subPage === 'Net BOE' && (
        <div className="data-table-title">
          BOE Daily Production Rate (Net Bbls)
        </div>
      )}
      {subPage === 'Cum Net Oil' && (
        <div className="data-table-title">
          Cum Net Oil (BO)
        </div>
      )}
      {subPage === 'Cum Net Gas' && (
        <div className="data-table-title">
          Cum Net Gas (MCF)
        </div>
      )}
      {subPage === 'Cum Net NGL' && (
        <div className="data-table-title">
          Cum Net NGL (Net Bbls)
        </div>
      )}
      {subPage === 'Cum Net BOE' && (
        <div className="data-table-title">
          Cum Net BOE (Net BOE vs Time)
        </div>
      )}
      <DataTableHeader />
      {subPage === 'Gross Oil' && (
        <DataTable
          attribute="oilGrossDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Gross Gas' && (
        <DataTable
          attribute="gasGrossDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Gross NGL' && (
        <DataTable
          attribute="nglGrossDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Cum Gross Oil' && (
        <DataTable
          attribute="oilGrossCum"
          type="number"
          decimals={0}
        />
      )}
      {subPage === 'Cum Gross Gas' && (
        <DataTable
          attribute="gasGrossCum"
          type="number"
          decimals={0}
        />
      )}
      {subPage === 'Cum Gross NGL' && (
        <DataTable
          attribute="nglGrossCum"
          type="number"
          decimals={0}
        />
      )}
      {subPage === 'Net Oil' && (
        <DataTable
          attribute="oilNetDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Net Gas' && (
        <DataTable
          attribute="gasNetDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Net NGL' && (
        <DataTable
          attribute="nglNetDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Net BOE' && (
        <DataTable
          attribute="boeNetDaily"
          type="number"
          decimals={1}
        />
      )}
      {subPage === 'Cum Net Oil' && (
        <DataTable
          attribute="oilNetCum"
          type="number"
          decimals={0}
        />
      )}
      {subPage === 'Cum Net Gas' && (
        <DataTable
          attribute="gasNetCum"
          type="number"
          decimals={0}
        />
      )}
      {subPage === 'Cum Net NGL' && (
        <DataTable
          attribute="nglNetCum"
          type="number"
          decimals={0}
        />
      )}
      {subPage === 'Cum Net BOE' && (
        <DataTable
          attribute="boeNetCum"
          type="number"
          decimals={0}
        />
      )}
    </div>
  );
};

export default Reserves;
