import * as React from 'react';
import PropTypes from 'prop-types';

function PrintViewLink({ tabName }) {
  return (
    <a
      href={`${window.location.origin}/print/index.html`}
      // href={env === 'development' ? `${window.location.origin}/print/` : `${window.location.origin}/print/index.html`}
      // Above code could be used to enable print view page in local development setting
      target="_blank"
      rel="noopener noreferrer"
      className="print-link"
    >
      {tabName}
    </a>
  );
}

PrintViewLink.propTypes = {
  tabName: PropTypes.string.isRequired,
};

export default PrintViewLink;
