import React, { useLayoutEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import * as Msal from "msal";

import msalConfig from '../auth/authConfig';

import '../styles/login.css';

const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};

// Checking navigator object to avoid causing server-side build to fail
const myMSALObj = typeof navigator !== `undefined` ? new Msal.UserAgentApplication(msalConfig) : null;
if (myMSALObj) {
  myMSALObj.handleRedirectCallback(authRedirectCallBack);
}

// Register Callbacks for Redirect flow
function authRedirectCallBack(error, response) {
  if (error) {
    console.log("Error:", error);
  } else {
    if (response.tokenType === "id_token") {
      console.log('id_token acquired at: ' + new Date().toString());
    } else if (response.tokenType === "access_token") {
      console.log('access_token acquired at: ' + new Date().toString());

      // callMSGraph(graphConfig.graphMailEndpoint, accessToken, updateUI);
      profileButton.style.display = 'none';
      mailButton.style.display = 'initial';
    } else {
      console.log("token type is:" + response.tokenType);
    }

    console.log("Waiting to redirect");
  }
}

const LoginForm = () => {
  // Use useSelector Hook to pull from Redux store
  const { status } = useSelector((state) => ({
    status: state.status,
  }), shallowEqual);

  useLayoutEffect(() => {
    let token = localStorage.getItem('msal.idtoken');
    if(!token) {
      signIn();
    }
  });

  // componentDidMount() {
  //     let sessionData = sessionStorage.getItem('msal.idtoken')  
  //     if(sessionData)
  //     {
  //         navigate('/')
  //     }
  // }

  const signIn = async () => {
    myMSALObj.loginRedirect(loginRequest);
  };

  if (status.loggedIn) {
    navigate('/');
  }

  return null;

  // return (
  //   <div className="login-screen">
  //     <div className="login-container">
  //       <div className="login-header">
  //         HEConomics
  //       </div>
  //       <button
  //         className="login-sso-button"
  //         onClick={signIn}
  //         type="button"
  //       >
  //         Log In
  //       </button>
  //     </div>
  //   </div>
  // );
};

export default LoginForm;
