/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Component imports */
import PlotLineGraph from './PlotLineGraph';
import DataTable from './DataTable';
import DataTableHeader from './DataTableHeader';

import {
  createPlotDataObjectArrayAllCases,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';
import '../styles/graphs.css';

const Taxes = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, results, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    results: state.results,
    status: state.status,
  }), shallowEqual);

  const netSevTaxPlotAllCases = createPlotDataObjectArrayAllCases(results, 'sevTaxNet');
  const netAdValoremTaxPlotAllCases = createPlotDataObjectArrayAllCases(results, 'adValTaxNet');

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      { subPage === 'Severance & Production Taxes' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={netSevTaxPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Severance & Production Taxes (Net $ per Month)"
            yAxisType="cartesian"
            title="Severance & Production Taxes"
            subTitle="Net $ per Month"
          />
        </div>
      )}
      { subPage === 'Ad Valorem Taxes' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={netAdValoremTaxPlotAllCases}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Ad Valorem Taxes (Net $ per Month)"
            yAxisType="cartesian"
            title="Ad Valorem Taxes"
            subTitle="Net $ per Month"
          />
        </div>
      )}
      {subPage === 'Severance & Production Taxes' && (
        <div>
          Severance & Production Taxes (Net $ per Month)
        </div>
      )}
      {subPage === 'Ad Valorem Taxes' && (
        <div>
          Ad Valorem Taxes (Net $ per Month)
        </div>
      )}

      <DataTableHeader />
      {subPage === 'Severance & Production Taxes' && (
        <DataTable
          attribute="sevTaxNet"
          type="currency"
          decimals={0}
        />
      )}
      {subPage === 'Ad Valorem Taxes' && (
        <DataTable
          attribute="adValTaxNet"
          type="currency"
          decimals={0}
        />
      )}
    </div>
  );
};

export default Taxes;
