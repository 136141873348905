/*
  Description: This component controls the right side of the screen on the Case Data page and provides key summary metrics
  to help determine how economically viable a project is based on the current inputs. This area only shows data when user
  has hit the Calculate button at the top of the screen
*/
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { convBoToMbo } from '../../../cashflowjs/dist/calcEconomicMetrics'

import { numberFormatter, currencyFormatter } from '../utils'

import '../styles/global.css'
import '../styles/cashflow.css'

const CashFlow = () => {
  // Use useSelector Hook to pull from Redux store
  const { risked, unrisked, ratioP10P90, status } = useSelector(
    (state) => ({
      risked: state.results.incrementalRisked,
      unrisked: state.results.incrementalUnrisked,
      ratioP10P90: state.results.ratioP10P90,
      status: state.status,
    }),
    shallowEqual
  )

  const RiskedPVRow = ({ risked, rowTitle, item }) => (
    <div className="cashflow-risked-section">
      <div className="cashflow-risked-section-header">{rowTitle}</div>
      {risked?.oneline && (
        <div className="cashflow-pv-values">
          {_.isFinite(risked?.oneline?.[item])
            ? currencyFormatter(risked?.oneline[item])
            : status.calcExecuted
            ? '-'
            : ' '}
        </div>
      )}
      {!risked && (
        <div className="cashflow-pv-values">
          {_.isFinite(item)
            ? numberFormatter(item.toFixed(2), 2)
            : status.calcExecuted
            ? '-'
            : ' '}
        </div>
      )}
      {risked && !risked?.oneline && (
        <div className="cashflow-pv-values">{` `}</div>
      )}
    </div>
  )

  const CashFlowRow = ({ risked, unrisked, rowTitle, object, item }) => {
    return (
      <div className="cashflow-risked-section">
        <div className="cashflow-yardsticks-section cashflow-large">
          {rowTitle}
        </div>
        <div
          className="cashflow-yardsticks-section cashflow-small font_8px"
          style={{ marginRight: '5px' }}
        >
          {risked?.[object] && ['ror', 'payout'].includes(item)
            ? risked?.[object]?.[item]
              ? item === 'payout' && risked?.[object]?.[item] < 0
                ? 'No Payout'
                : risked?.[object]?.[item]
              : status.calcExecuted
              ? '-'
              : ' '
            : risked?.[object] && ['pvr0', 'pvr10', 'pvr15'].includes(item)
            ? risked?.[object]?.[item]
              ? numberFormatter(risked[object][item], 2)
              : status.calcExecuted
              ? '-'
              : ' '
            : risked?.[object] && item === 'boeNet'
            ? numberFormatter(convBoToMbo(risked?.[object]?.[item]))
            : risked?.[object] && ['dollarsPerBOE'].includes(item)
            ? risked?.[object]?.[item]
              ? isNaN(risked?.[object]?.[item])
                ? 'N/A'
                : currencyFormatter(risked?.[object]?.[item], 2)
              : status.calcExecuted
              ? 'N/A'
              : ' '
            : risked?.[object] &&
              [
                'dollarsPerBOEPD',
                'initialNetOperatingIncome',
                'capitalNet',
              ].includes(item)
            ? risked?.[object]?.[item] || risked?.[object]?.[item] == 0
              ? currencyFormatter(risked?.[object][item])
              : status.calcExecuted
              ? '-'
              : ' '
            : status.calcExecuted
            ? '-'
            : ' '}
        </div>
        <div className="cashflow-yardsticks-section cashflow-small font_8px">
          {unrisked?.[object] && ['ror', 'payout'].includes(item)
            ? unrisked?.[object][item]
              ? item === 'payout' && unrisked?.[object]?.[item] < 0
                ? 'No Payout'
                : unrisked?.[object][item]
              : status.calcExecuted
              ? '-'
              : ' '
            : unrisked?.[object] && ['pvr0', 'pvr10', 'pvr15'].includes(item)
            ? risked?.[object][item]
              ? numberFormatter(unrisked[object][item], 2)
              : status.calcExecuted
              ? '-'
              : ' '
            : unrisked?.[object] && item === 'boeNet'
            ? numberFormatter(convBoToMbo(unrisked[object][item]))
            : unrisked?.[object] && ['dollarsPerBOE'].includes(item)
            ? unrisked?.[object]?.[item]
              ? isNaN(unrisked?.[object]?.[item])
                ? 'N/A'
                : currencyFormatter(unrisked?.[object][item], 2)
              : status.calcExecuted
              ? 'N/A'
              : ' '
            : unrisked?.[object] &&
              [
                'dollarsPerBOEPD',
                'initialNetOperatingIncome',
                'capitalNet',
              ].includes(item)
            ? unrisked?.[object]?.[item] || unrisked?.[object]?.[item] == 0
              ? currencyFormatter(unrisked?.[object][item])
              : status.calcExecuted
              ? '-'
              : ' '
            : status.calcExecuted
            ? '-'
            : ' '}
        </div>
      </div>
    )
  }

  return (
    <div className="cashflow">
      <div className="section-header">Economic Metrics</div>
      <div className="cashflow-yardsticks">
        <div className="cashflow-yardsticks-header">
          {[' ', 'Risked', 'Unrisked'].map((item, index) => (
            <div
              key={index}
              className={
                item === ' ' ? 'cashflow-large-header' : 'cashflow-small-header'
              }
              style={{ justifyContent: 'center' }}
            >
              {item}
            </div>
          ))}
        </div>
        <CashFlowRow
          rowTitle="PVR-0%"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="pvr0"
        />
        <CashFlowRow
          rowTitle="PVR-10%"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="pvr10"
        />
        <CashFlowRow
          rowTitle="PVR-15%"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="pvr15"
        />
        <CashFlowRow
          rowTitle="IRR %"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="ror"
        />
        <CashFlowRow
          rowTitle="Net Invest. ($)"
          risked={risked}
          unrisked={unrisked}
          object="oneline"
          item="capitalNet"
        />
        <CashFlowRow
          rowTitle="Init. Net CF ($/mo)"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="initialNetOperatingIncome"
        />
        <CashFlowRow
          rowTitle="Payout (mos)"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="payout"
        />
        <CashFlowRow
          rowTitle="Net MBOE Res."
          risked={risked}
          unrisked={unrisked}
          object="oneline"
          item="boeNet"
        />
        <CashFlowRow
          rowTitle="Dev. $ / BOE"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="dollarsPerBOE"
        />
        <CashFlowRow
          rowTitle="Dev. $ / BOEPD"
          risked={risked}
          unrisked={unrisked}
          object="economicMetrics"
          item="dollarsPerBOEPD"
        />
      </div>
      <div className="cashflow-risked">
        <div className="cashflow-risked-header">Risked PV Profile</div>
        <RiskedPVRow rowTitle="PV-0%" risked={risked} item="netIncome" />
        <RiskedPVRow rowTitle="PV-10%" risked={risked} item="presentValue10" />
        <RiskedPVRow rowTitle="PV-15%" risked={risked} item="presentValue15" />
        <RiskedPVRow rowTitle="PV-20%" risked={risked} item="presentValue20" />
        <RiskedPVRow rowTitle="PV-25%" risked={risked} item="presentValue25" />
        <RiskedPVRow rowTitle="PV-50%" risked={risked} item="presentValue50" />
        <RiskedPVRow
          rowTitle="PV-100%"
          risked={risked}
          item="presentValue100"
        />
        <RiskedPVRow
          rowTitle="PV-200%"
          risked={risked}
          item="presentValue200"
        />
        <RiskedPVRow
          rowTitle="PV-300%"
          risked={risked}
          item="presentValue300"
        />
        <RiskedPVRow rowTitle="Ratio P10/P90" item={ratioP10P90} />
      </div>
    </div>
  )
}

export default CashFlow
