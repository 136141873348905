/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Component imports */
import MonthlyDataView from './MonthlyDataView';

import { 
  createPlotDataObjectArrayAllCases,
  currencyFormatter,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';

const PresentValue = () => {
  const [headers] = React.useState(['Month', 'Inc. Net Income', 'PV @ 20%', 'PV @ 25%', 'PV @ 50%', 'PV @ 100%', 'PV @ 200%', 'PV @ 300%' ]);

  // Use useSelector Hook to pull from Redux store
  const { cashflow, results, status, subPage } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    cashflow: state?.results?.incrementalRisked?.cashflow || { },
    results: state.results,
    status: state.status
  }), shallowEqual);

  const netIncomePV10PlotAllCases = createPlotDataObjectArrayAllCases(results, 'presentValue10');
  const netIncomeCumPV10PlotAllCases = createPlotDataObjectArrayAllCases(results, 'presentValue10Cum');
  const netIncomePV15PlotAllCases = createPlotDataObjectArrayAllCases(results, 'presentValue15');
  const netIncomeCumPV15PlotAllCases = createPlotDataObjectArrayAllCases(results, 'presentValue15Cum');

  const { netIncome, presentValue20, presentValue25, presentValue50, presentValue100, presentValue200, presentValue300 } = cashflow;

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      {subPage === 'PV-10 Net Income' && (
        <MonthlyDataView
          attribute="presentValue10"
          dataArray={netIncomePV10PlotAllCases}
          decimals={0}
          title="PV-10 Net Income"
          subTitle="$ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="PV-10 Net Income ($ per Month)"
        />
      )}
      {subPage === 'Cumulative PV-10 Net Income' && (
        <MonthlyDataView
          attribute="presentValue10Cum"
          dataArray={netIncomeCumPV10PlotAllCases}
          decimals={0}
          title="Cumulative PV-10 Net Income"
          subTitle="Discounted Net $ vs Time"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Cum PV-10 Net Income (Discounted Net $ vs Time)"
        />
      )}
      {subPage === 'PV-15 Net Income' && (
        <MonthlyDataView
          attribute="presentValue15"
          dataArray={netIncomePV15PlotAllCases}
          decimals={0}
          title="PV-15 Net Income"
          subTitle="$ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="PV-15 Net Income ($ per Month)"
        />
      )}
      {subPage === 'Cumulative PV-15 Net Income' && (
        <MonthlyDataView
          attribute="presentValue15Cum"
          dataArray={netIncomeCumPV15PlotAllCases}
          decimals={0}
          title="Cumulative PV-15 Net Income"
          subTitle="Discounted Net $ vs Time"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Cum PV-15 Net Income (Discounted Net $ vs Time)"
        />
      )}
      {subPage === 'PV Profiles' && (
        <div className="data-table-title">
          PV Profiles
        </div>
      )}
      {subPage === 'PV Profiles' && (
        <div className="data-table-header">
          {headers.map(headerName => <div>{headerName}</div>)}
      </div>
      )}
      { subPage === 'PV Profiles' && (
        <div className="data-table-container">
          <table width="100%" border>
            <tbody height={600}>
              {netIncome?.map((item, index) => {
                  return (
                    <tr key={index} height={25}>
                      <td textAlign="right">
                        {index + 1}
                      </td>
                      <td textAlign="right">
                        {netIncome && currencyFormatter(netIncome[index])}
                      </td>
                      <td textAlign="right">
                        {presentValue20 && currencyFormatter(presentValue20[index])}
                      </td>
                      <td textAlign="right">
                        {presentValue25 && currencyFormatter(presentValue25[index])}
                      </td>
                      <td textAlign="right">
                        {presentValue50 && currencyFormatter(presentValue50[index])}
                      </td>
                      <td textAlign="right">
                        {presentValue100 && currencyFormatter(presentValue100[index])}
                      </td>
                      <td textAlign="right">
                        {presentValue200 && currencyFormatter(presentValue200[index])}
                      </td>
                      <td textAlign="right">
                        {presentValue300 && currencyFormatter(presentValue300[index])}
                      </td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default PresentValue;
