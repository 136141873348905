import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import ProjectsTable from './ProjectsTable'
import TooltipIcon from './TooltipIcon'
import { isShowingOPEXModal, updateValues } from '../actions'

const XLSX = require('xlsx')

const CustomOPEXModal = ({}) => {
  const [file, setFile] = React.useState({});
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [grossFixedWellCostArray, setGrossFixedWellCostArray] = React.useState([
    { cost: 0, month: 0 },
  ])

  const { status } = useSelector(
    (state) => ({
      status: state.status,
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(isShowingOPEXModal(false))
  }

  const handleRemove = (e) => {
    console.log(e);
  }

  const handleSubmit = () => {
    console.log('Submitted!');
  }
  const addRow = () => {
    setOpexArray([
      ...opexArray,
      { index: opexArray.length, gas: 0, water: 0, oil: 0, months: 0 },
    ])
  }

  const handleFiles = (files) => {
    setFile(files[0]);
    console.log(files);
    setErrorMessage('');
    setFileUploaded(true);
  }

  const submitFixedWellCost = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, {type: 'array'});
      let finalArray, fixedWellCost;
      if (workbook.SheetNames.find(item => item === 'FixedWellCost' || item === 'Sheet1')) {
        if(workbook.SheetNames.find(item => item === 'FixedWellCost')) {
          console.log("Found FixedWellCost sheet");
          fixedWellCost = XLSX.utils.sheet_to_json(workbook.Sheets.FixedWellCost);
        } else if(workbook.SheetNames.find(item => item === 'Sheet1')) {
          console.log("Found Sheet1.");
          fixedWellCost = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet1);
        }
        console.log('Uploaded data:', fixedWellCost);
        setFileUploaded(false);

        let cases = ['p90', 'p50', 'p10', 'failure', 'existing'];
        
        for(let caseElement of cases) {
          console.log("Case:", caseElement);
          if(fixedWellCost.find(item => item[caseElement])) {
            let costArray = fixedWellCost.map(item => item[caseElement]);
            if(costArray.length < 600) {
            console.log("Length of cost array:", costArray.length);
            let numFillItems = 600 - costArray.length;
            finalArray = costArray.concat(new Array(numFillItems).fill(costArray[costArray.length - 1]));
            console.log("Final array:", finalArray);
            } else {
            finalArray = costArray;
            console.log("Final array:", finalArray);
            }
            dispatch(
              updateValues(
                caseElement,
                'opexPerWellGross',
                finalArray,
              ),
            )
          } else {
            console.log("No case data found... Skipping.")
          }
        }

        dispatch(isShowingOPEXModal(false))

        // if (fixedWellCost.find(item => item.P90)) {
        //   let costArray = fixedWellCost.map(item => item.P90);
        //   if(costArray.length < 600) {
        //     console.log("Length of cost array:", costArray.length);
        //     let numFillItems = 600 - costArray.length;
        //     finalArray = costArray.concat(new Array(numFillItems).fill(costArray[costArray.length - 1]));
        //     console.log("Final array:", finalArray);
        //   } else {
        //     finalArray = costArray;
        //     console.log("Final array:", finalArray);
        //   }
        //   dispatch(
        //     updateValues(
        //       'p10',
        //       'opexPerWellGross',
        //       finalArray,
        //     ),
        //   )
        //   dispatch(
        //     updateValues(
        //       'p50',
        //       'opexPerWellGross',
        //       finalArray,
        //     ),
        //   )
        //   dispatch(
        //     updateValues(
        //       'p90',
        //       'opexPerWellGross',
        //       finalArray,
        //     ),
        //   )
        //   dispatch(
        //     updateValues(
        //       'failure',
        //       'opexPerWellGross',
        //       finalArray,
        //     ),
        //   )
        //   dispatch(
        //     updateValues(
        //       'existing',
        //       'opexPerWellGross',
        //       finalArray,
        //     ),
        //   )
        //   dispatch(isShowingOPEXModal(false))
        //   setErrorMessage('')
        // } else {
        //   setErrorMessage(
        //     'Error: Invalid format. Forecast sheet must contain a column titled Month and a column titled Cost.',
        //   )
        // }
      // } else {
      //   setErrorMessage(
      //     'Error: Invalid format. Excel file must contain one sheet named FixedWellCost.',
      //   )
        // dispatch(
        //   updateValues(status.customForecastCase, 'customProdForecast', []),
        // )
      }
      setFile({})
    }
    reader.readAsArrayBuffer(file)
  }


  return (
    <div style={{ maxWidth: '80%' }}>
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={status.isShowingOPEXModal}
    >
      {
        <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1>Custom Gross Fixed Well Cost Input Form</h1>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <div style={{ width: '80%' }}>
            <Dropzone onDrop={(acceptedFiles) => handleFiles(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag & Drop a file, or click here for a file selector.
                      <br />
                      Excel file expected, containing a worksheet named FixedWellCost or Sheet1
                      with columns named 'p90', 'p50', 'p10', 'failure' and 'existing' to match the case
                      to which they will be applied. All columns are optional.
                      <br />
                      Each column should contain monthly fixed well costs. If fewer than 600 values (or less than 50 years)
                      are provided, the last value will be repeated up to the 600th month for the sake of the calculation.
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            </div>
            <div style={{ display: 'flex', margin: '20px 0' }}>
              <div
                style={{
                  width: '20%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                }}
              >
                {fileUploaded === true && <button
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    border: '1px solid green',
                    borderRadius: '4px',
                    padding: '5px 10px',
                  }}
                  onClick={submitFixedWellCost}
                >
                  Submit
                </button>}
                {fileUploaded === false && <button disabled
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    border: '1px solid green',
                    borderRadius: '4px',
                    padding: '5px 10px',
                    opacity: 0.2
                  }}
                >
                  Submit
                </button>}
              </div>
            </div>
          </div>
        </div>
      }
    </Dialog>
    </div>
  )
}

export default CustomOPEXModal
