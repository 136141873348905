import React from 'react'

import RowTitle from './RowTitle'

import {
  numberFormatter,
  CASE_HEADERS,
  CASE_ROW_HEIGHT,
  CURRENCY_FIELDS,
} from '../utils'
import {formatHEConomicsNumber} from '../utils/formatHEConomicsNumber'

type CalculatedRowProps = {
  rowTitle: string
  results: any
  subObject: 'oneline' | 'economicMetrics'
  dataName: string
  decimals: number
  children: React.ReactNode
}

const CalculatedRow = ({
  rowTitle,
  results,
  subObject,
  dataName,
  decimals,
  children,
}: CalculatedRowProps) => {
  return (
    <tr className="table-row" height={CASE_ROW_HEIGHT}>
      <RowTitle title={rowTitle}>{children}</RowTitle>
      {CASE_HEADERS.map((caseName, index) => {
        if (dataName === 'ror') {
          return (
            <td key={index}>
              <div className="case-input">
                {results?.[caseName]?.[subObject]?.[dataName]
                  ? `${results[caseName][subObject][dataName]}`
                  : '-'}
              </div>
            </td>
          )
        } else if (dataName === 'initialDailyRate') {
          return (
            <td key={index}>
              <div className="case-input">
                {results?.[caseName]?.initialDailyRate
                  ? results[caseName].initialDailyRate
                  : '-'}
              </div>
            </td>
          )
        } else if (dataName === 'payout') {
          return (
            <td key={index}>
              <div className="case-input">
                {results?.[caseName]?.[subObject]?.[dataName]
                  ? results?.[caseName]?.[subObject]?.[dataName] === -1
                    ? 'No Payout'
                    : numberFormatter(
                        results[caseName][subObject][dataName],
                        decimals,
                      )
                  : '-'}
              </div>
            </td>
          )
        } else {
          return (
            <td key={index}>
              <div className="case-input">
                {formatHEConomicsNumber(
                  CURRENCY_FIELDS.includes(dataName),
                  decimals,
                  results?.[caseName]?.[subObject]?.[dataName],
                )}
              </div>
            </td>
          )
        }
      })}
    </tr>
  )
}

export default CalculatedRow
