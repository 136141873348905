import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

import {
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';

const Sensitivity = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, results, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    results: state.results,
    status: state.status,
  }), shallowEqual);

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '25vh' }}>Coming soon! Sensitivity graph</div>
    </div>
  );
};

export default Sensitivity;
