/* Third party npm package imports */
import * as React from 'react';
import PropTypes from 'prop-types';

/* HEConomics component imports */
import PlotLineGraph from './PlotLineGraph';
import DataTable from './DataTable';
import DataTableHeader from './DataTableHeader';

const MonthlyDataView = ({
  attribute,
  dataArray,
  yAxisLabel,
  title,
  subTitle,
  type,
  yAxisType,
  decimals,
}) => (
  <>
    <div className="graphs">
      <PlotLineGraph
        plotDataArray={dataArray}
        width="1000"
        height="540"
        xAxisLabel="Year"
        yAxisLabel={yAxisLabel}
        yAxisType={yAxisType}
        title={title}
        subTitle={subTitle}
      />
    </div>
    <div>
      {`${title} (${subTitle})`}
    </div>
    <DataTableHeader />
    <DataTable
      attribute={attribute}
      type={type}
      decimals={decimals || 0}
    />
  </>
);

MonthlyDataView.propTypes = {
  attribute: PropTypes.string.isRequired,
  dataArray: PropTypes.array.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  yAxisType: PropTypes.string.isRequired,
  decimals: PropTypes.number,
};

MonthlyDataView.defaultProps = {
  decimals: 0,
};

export default MonthlyDataView;
