import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Dropzone from 'react-dropzone';

import { updatePrices } from '../actions';

const _ = require('lodash');
const XLSX = require('xlsx');

const PricesAdminButtons = () => {
  const [inputKey, setInputKey] = React.useState(1);
  const [showPriceUpload, setShowPriceUpload] = React.useState(false);
  const [file, setFile] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [forecastName, setForecastName] = React.useState('');

  const { prices, status } = useSelector((state) => ({
    prices: state.prices,
    status: state.status,
  }),
  shallowEqual);

  const dispatch = useDispatch();

  const downloadPriceSheet = () => {
    const priceDeck = prices.find(price => price.PriceName === status.priceFilter);
    console.log(priceDeck);
    let convertedPriceDeck = priceDeck?.Oil.map((item, index) => ({ Oil: priceDeck.Oil[index], Gas: priceDeck.Gas[index] }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(convertedPriceDeck, { header: ['Gas', 'Oil'] });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Forecast');
    XLSX.writeFile(workbook, `HEConomics-Prices-${status.priceFilter}-${moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  const saveForecast = (e) => {
    dispatch(updatePrices(file, forecastName));
    setFile({});
    setForecastName('');
    setShowPriceUpload(false);
  };

  const showPriceUploadWindow = () => {
    setShowPriceUpload(true);
    setInputKey(Math.random().toString(36));
  }

  const handleChange = (event) => {
    setForecastName(event.target.value);
  }

  const handleClose = () => {
    setShowPriceUpload(false);
    setFile({});
    setForecastName('');
  }

  const handleFiles = (files) => {
    setFile(files[0]);
    console.log(files);
    setErrorMessage('');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} open={showPriceUpload}>
      <div className="customForecastDialog">
        <div className="customForecastHeader">
          Upload Price Forecast
        </div>
        {errorMessage !== '' && (
          <div style={{ color: 'red', padding: '1rem 0' }}>
            {errorMessage}
          </div>
        )}
      <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div aria-label="prices-dropzone" className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop a file here, or click to select an Excel file. <br /> File should contain a tab named 'Forecast' with 'Oil' and 'Gas' columns.</p>
            </div>
          </section>
        )}
      </Dropzone>
      {file?.name && (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0', width: '80%' }}>
        <div className="price-forecast-name">
          {`Forecast Name: `}
          {<input value={forecastName} onChange={(e) => handleChange(e)} />}
        </div>
        <div className="clear-link">
          {<button
             style={{ border: '1px solid #3498db', backgroundColor: '#3498db', color: 'white', borderRadius: '2px', minWidth: '100px' }}
             onClick={saveForecast}
           >
             Save
           </button>}
        </div>
      </div>
      )}
      </div>
    </Dialog>
        <LowerCaseButton
          aria-label="prices-upload"
          variant="contained"
          color="primary"
          style={{ padding: '0.2rem 1rem' }}
          onClick={showPriceUploadWindow}
          component="span"
        >
          Upload & Overwrite
        </LowerCaseButton>
      <LowerCaseButton
        aria-label="download"
        variant="contained"
        color="primary"
        style={{ padding: '0.2rem 1rem', margin: '0 0 0 1rem' }}
        onClick={downloadPriceSheet}
      >
        Download
      </LowerCaseButton>
    </div>
  );
};

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'rgb(85, 108, 120)',
    minWidth: '100px',
    minHeight: '40px',
  },
})(Button);

export default PricesAdminButtons;
