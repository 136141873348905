/* NPM module imports */
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Component imports */
import MonthlyDataView from './MonthlyDataView';

import {
  createPlotDataObjectArrayAllCases,
  PRE_CALCULATION_WARNING_MESSAGE,
} from '../utils';

import '../styles/graphs.css';

const NetIncome = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, results, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    results: state.results,
    status: state.status,
  }), shallowEqual);

  const netIncomePlotAllCases = createPlotDataObjectArrayAllCases(results, 'netIncome');
  const netIncomeCumPlotAllCases = createPlotDataObjectArrayAllCases(results, 'netIncomeCum');
  const netOperatingIncomePlotAllCases = createPlotDataObjectArrayAllCases(results, 'netOperatingIncome');
  const netOperatingIncomeCumPlotAllCases = createPlotDataObjectArrayAllCases(results, 'netOperatingIncomeCum');

  if (!status.calcExecuted) {
    return <div className="calculation-warning"><Alert severity="warning">{PRE_CALCULATION_WARNING_MESSAGE}</Alert></div>;
  }

  return (
    <div className="full-data-grid">
      {subPage === 'Net Income' && (
        <MonthlyDataView
          attribute="netIncome"
          dataArray={netIncomePlotAllCases}
          decimals={0}
          title="Net Income"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Income (Net $ per Month)"
        />
      )}
      { subPage === 'Cumulative Net Income' && (
        <MonthlyDataView
          attribute="netIncomeCum"
          dataArray={netIncomeCumPlotAllCases}
          decimals={0}
          title="Cumulative Net Income"
          subTitle="Net $ vs Time"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Cumulative Net Income (Net $ vs Time)"
        />
      )}
      { subPage === 'Net Operating Income' && (
        <MonthlyDataView
          attribute="netOperatingIncome"
          dataArray={netOperatingIncomePlotAllCases}
          decimals={0}
          title="Net Operating Income"
          subTitle="Net $ per Month"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Net Operating Income (Net $ per Month)"
        />
      )}
      { subPage === 'Cumulative Net Operating Income' && (
        <MonthlyDataView
          attribute="netOperatingIncomeCum"
          dataArray={netOperatingIncomeCumPlotAllCases}
          decimals={0}
          title="Cumulative Net Operating Income"
          subTitle="Net $ vs Time"
          type="currency"
          yAxisType="cartesian"
          yAxisLabel="Cumulative Net Operating Income (Net $ vs Time)"
        />
      )}
    </div>
  );
};

export default NetIncome;
