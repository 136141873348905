import * as React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dropzone from 'react-dropzone'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import {Button, Tooltip} from '@chakra-ui/react'

import UserProjects from './UserProjects'

import {
  MAX_CHARACTERS_PRICE_FORECAST_NAME,
  MIN_PRICE_FORECAST_MONTHS,
} from '../utils'

import {updateSettings} from '../actions'

const XLSX = require('xlsx')

const UserProjectsOrSpinner = ({projectIds, isGettingProjectIds}) =>
  isGettingProjectIds ? (
    <CircularProgress />
  ) : (
    <UserProjects projectIds={projectIds} />
  )

const UserSettings = () => {
  const {
    projectIds,
    settings,
    user,
    navigation,
    status,
    isGettingProjectIds,
  } = useSelector(state => {
    return {
      projectIds: state.projectIds,
      settings: state.settings,
      user: state.user,
      navigation: state.navigation,
      status: state.status,
      isGettingProjectIds: state.status.isGettingProjectIds,
    }
  }, shallowEqual)

  const [file, setFile] = React.useState<null | Blob>(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [forecastName, setForecastName] = React.useState('')
  const [
    priceForecastNameToDelete,
    setPriceForecastNameToDelete,
  ] = React.useState('')
  const [
    isConfirmDeletePriceModalOpen,
    setIsConfirmDeletePriceModalOpen,
  ] = React.useState(false)

  const dispatch = useDispatch()

  const userSettings = settings.find(item => item.User === user.userName)

  const handleClickDeletePriceForecast = priceForecastNameToDelete => {
    setPriceForecastNameToDelete(priceForecastNameToDelete)
    setIsConfirmDeletePriceModalOpen(true)
  }

  const forceDeletePriceForecast = () => {
    let existingForecasts = settings.find(item => item.User === user.userName)
      ?.PriceForecasts
    let newForecasts = existingForecasts.filter(
      item => item.PriceName !== priceForecastNameToDelete,
    )
    console.log(
      'Deleting price forecast in UserSettings.  About to save the following price forecasts...',
    )
    console.log(newForecasts)
    dispatch(updateSettings('PriceForecasts', newForecasts, user.userName))
    setIsConfirmDeletePriceModalOpen(false)
  }

  const handleChange = event => {
    setForecastName(event.target.value)
  }

  const handleFiles = files => {
    setFile(files[0])
    console.log(files)
    setErrorMessage('')
  }

  const saveForecast = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const data = new Uint8Array(reader.result)
      const workbook = XLSX.read(data, {type: 'array'})
      if (workbook.SheetNames.find(item => item === 'Forecast')) {
        let forecast = XLSX.utils.sheet_to_json(workbook.Sheets.Forecast)
        console.log('Uploaded data:', forecast)
        let formattedForecast = {
          PriceName: forecastName,
          Oil: forecast.map(item => item.Oil),
          Gas: forecast.map(item => item.Gas),
        }
        console.log('Formatted:', formattedForecast)
        // if(forecast.find(item => item.Oil && item.Gas)) {
        if (
          forecast.every(
            item => Number.isFinite(item.Oil) && Number.isFinite(item.Gas),
          ) &&
          forecast.length >= MIN_PRICE_FORECAST_MONTHS
        ) {
          let existingForecasts = settings.find(
            item => item.User === user.userName,
          )?.PriceForecasts
          if (existingForecasts && existingForecasts.length > 0) {
            dispatch(
              updateSettings(
                'PriceForecasts',
                [...existingForecasts, formattedForecast],
                user.userName,
              ),
            )
          } else {
            dispatch(
              updateSettings(
                'PriceForecasts',
                [formattedForecast],
                user.userName,
              ),
            )
          }
          setErrorMessage('')
        } else {
          setErrorMessage(`Error: Invalid format. The Forecast sheet must contain a column named Oil
            and a column named Gas.  Each column should contain at least ${MIN_PRICE_FORECAST_MONTHS}
            rows containing numbers that represent the price forecast by month.
            The last price will be repeated to the end of economic life.  The data you provided
            follows:
            Oil: ${formattedForecast.Oil}
            Gas: ${formattedForecast.Gas}`)
        }
      } else {
        setErrorMessage(
          'Error: Invalid format. Excel file must contain one sheet named Forecast.',
        )
      }
      setFile(null)
      setForecastName('')
    }

    reader.readAsArrayBuffer(file)
  }

  return (
    <div
      style={{
        minHeight: '85vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '0 auto',
        padding: '2rem',
        display: 'flex',
      }}
    >
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setIsConfirmDeletePriceModalOpen(false)}
        open={isConfirmDeletePriceModalOpen}
      >
        <div className="customForecastDialog">
          <div>
            Are you sure you would like to delete the "
            {priceForecastNameToDelete}" price forecast?
          </div>
          <div
            style={{display: 'flex', justifyContent: 'flex-end', width: '80%'}}
          >
            <button
              style={{
                margin: '0 2rem',
                border: '1px solid firebrick',
                backgroundColor: 'firebrick',
                color: 'white',
              }}
              onClick={() => setIsConfirmDeletePriceModalOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={forceDeletePriceForecast}
              style={{
                backgroundColor: 'rgb(45, 103, 91)',
                color: 'white',
                border: '1px solid rgb(45, 103, 91)',
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <div className="settings">
        {navigation.subPage === 'My Projects' && (
          <UserProjectsOrSpinner
            projectIds={projectIds}
            isGettingProjectIds={isGettingProjectIds}
          />
        )}
        {navigation.subPage === 'Custom Price Forecasts' && (
          <div className="customForecastDialog">
            <div className="customForecastHeader">
              {`Custom Price Forecast Upload`}
            </div>
            <div className="customForecastInstructions"></div>
            {errorMessage !== '' && (
              <div style={{color: 'red', padding: '1rem 0'}}>
                {errorMessage}
              </div>
            )}
            <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      <b>Drag & Drop</b> an Excel price file here (or click here
                      for a file select dialog). The file must contain oil and
                      gas price values by month. Details...
                    </p>
                    <p>
                      <b>Worksheet Requirements:</b> The Excel price file must
                      contain a worksheet named 'Forecast' that contains two
                      columns named 'Oil' and 'Gas'. The rows contain prices
                      ($/BO and $/MCF) for each month. At least{' '}
                      {MIN_PRICE_FORECAST_MONTHS} row of data is required.
                    </p>
                    <p>
                      <b>Last Price Extended:</b> The last price in the table
                      will be extended to the life of the economic project.
                    </p>
                    <p>
                      <b>Relative Months:</b> The first row of data will
                      coincide with the first month of the HEConomics forecast
                      (prices are not assigned to a certain absolute date).
                    </p>
                    <p>
                      <b>Price Dropdown:</b> Your custom prices will be
                      available via the price selection dropdown menu on the
                      Case Data page.
                    </p>
                    <p>
                      <b>After Selecting a File:</b> Enter a forecast name and
                      click save.
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1rem 0',
                width: '80%',
              }}
            >
              <div className="price-forecast-name">
                {`Forecast Name: `}
                <input
                  value={forecastName}
                  onChange={e => handleChange(e)}
                  maxLength={MAX_CHARACTERS_PRICE_FORECAST_NAME}
                />
              </div>
              <div className="clear-link">
                <Tooltip
                  label="Before saving, select a file and enter a forecast name"
                  shouldWrapChildren
                  isDisabled={file !== null && forecastName.length > 0}
                >
                  <Button
                    aria-label="save-forecast"
                    style={{
                      border: '1px solid #3498db',
                      backgroundColor: '#3498db',
                      color: 'white',
                      borderRadius: '2px',
                      minWidth: '100px',
                    }}
                    isDisabled={file === null || forecastName.length === 0}
                    onClick={saveForecast}
                  >
                    Save
                  </Button>
                </Tooltip>
              </div>
            </div>

            {status.loadingPriceForecast && <CircularProgress />}

            {!!userSettings?.PriceForecasts?.length && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Divider style={{width: '100%', margin: '1em'}} />
                <div>
                  Available Custom Forecasts (
                  {userSettings?.PriceForecasts?.length}):
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '60%',
                    marginTop: '1rem',
                  }}
                >
                  <div className="data-table-header">
                    <div
                      style={{
                        display: 'flex',
                        width: '50%',
                        paddingLeft: '20px',
                      }}
                    >
                      Custom Forecast Name
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'center',
                      }}
                    >
                      Action
                    </div>
                  </div>
                  {userSettings?.PriceForecasts?.map((forecast, index) => (
                    <div
                      key={index + forecast.PriceName}
                      style={{
                        display: 'flex',
                        width: '100%',
                        borderLeft: '1px solid #DDD',
                        borderBottom: '1px solid #DDD',
                        borderRight: '1px solid #DDD',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '50%',
                          paddingLeft: '20px',
                          alignItems: 'center',
                        }}
                      >
                        {forecast.PriceName}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '50%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <button
                          aria-label={forecast.PriceName}
                          className="clear-link"
                          onClick={() =>
                            handleClickDeletePriceForecast(forecast.PriceName)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {/* <div style={{ display: 'flex', width: '50%', margin: '2rem 0' }}>
          <div style={{ padding: '0 1rem', display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0 0.2rem' }}>Feedback: </div>
            <Tooltip style={{ color: 'gray', fontSize: '15px' }} title="Enter feedback and app suggestions here.">
              <InfoOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </div>
          <div style={{ width: '100%' }}>
            <textarea style={{ width: '100%' }} rows={6} />
          </div>
        </div>
        <div>
          <button style={{ backgroundColor: 'blue', border: '1px solid blue', borderRadius: '2px' }} type="button">Submit</button>
        </div> */}
      </div>
    </div>
  )
}

export default UserSettings
