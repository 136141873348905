import React from 'react'
import {Tab, Tablist} from 'evergreen-ui'
import {connect} from 'react-redux'

import {changeSubPage, changeTab} from '../actions'

class SubNavigation extends React.Component {
  componentDidMount() {
    switch (this.props.page) {
      case 'Admin':
        this.props.changeSubPage('Diffs & OPEX')
        this.props.changeTab('')
        break
      case 'Case Data':
        this.props.changeSubPage('Cases')
        this.props.changeTab('')
        break
      case 'Reserves':
        this.props.changeSubPage('Gross Oil')
        this.props.changeTab('')
        break
      case 'Revenue':
        this.props.changeSubPage('Total Revenue')
        this.props.changeTab('')
        break
      case 'Taxes':
        this.props.changeSubPage('Net Severance & Production Taxes ($/mo)')
        this.props.changeTab('')
        break
      case 'OpEx':
        this.props.changeSubPage('Gross Operating Cost')
        this.props.changeTab('')
        break
      case 'PV10':
        this.props.changeSubPage('PV10 Net Income')
        this.props.changeTab('')
        break
      case 'PV15':
        this.props.changeSubPage('PV15 Net Income')
        this.props.changeTab('')
        break
      case 'Present Value':
        this.props.changeSubPage('PV10')
        this.props.changeTab('')
        break
      // case 'IRR':
      //     break;
      // case 'Incremental':
      //     break;
      case 'User':
        this.props.changeSubPage('My Projects')
        this.props.changeTab('')
        break
      // case 'Sensitivity':
      //     this.props.changeSubPage('Tornado');
      //     this.props.changeTab('');
      default:
        break
    }
  }

  changeSubPage = page => {
    this.props.changeSubPage(page)
  }

  render() {
    const {navigation, subPage} = this.props
    return (
      <div className="navigation page-navigation">
        <Tablist>
          {navigation.map((tab, index) => (
            <Tab
              key={index}
              value={tab}
              is="div"
              id={tab}
              height={30}
              isSelected={tab === subPage}
            >
              <div
                aria-label={`change-to-${tab}`}
                onClick={() => this.changeSubPage(tab)}
              >
                {tab}
              </div>
            </Tab>
          ))}
        </Tablist>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    page: state.navigation.page,
    subPage: state.navigation.subPage,
    navigation: state.navigation?.navigationArray || [],
  }
}

const mapDispatchToProps = {
  changeSubPage,
  changeTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubNavigation)
