import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { updateDefaults } from '../actions';

import {
  SPREADSHEET_FIELD_TRANSLATION,
} from '../utils';

const XLSX = require('xlsx');

const DefaultsAdminButtons = () => {
  const [inputKey, setInputKey] = React.useState(1);

  const { defaults } = useSelector((state) => ({
    defaults: state.defaults,
  }),
  shallowEqual);

  const dispatch = useDispatch();

  const downloadDefaultsSheet = () => {
    const workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(defaults, { header: SPREADSHEET_FIELD_TRANSLATION.map((item) => item.name) });
    Object.keys(worksheet).filter(item => item.includes('1') && item.length === 2).forEach(item => {
      let property = SPREADSHEET_FIELD_TRANSLATION.find(translationItem => translationItem.name === worksheet[item].v);
      if (property) {
        worksheet[item].v = property.prettyName;
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Defaults');
    XLSX.writeFile(workbook, `HEConomics_Defaults_${moment().format('YYYY-MM-DD_hh-mm')}.xlsx`);
  };

  // Changes the key on the input to force a re-render on upload.
  // Allows same file name to be used with updated values.
  // Otherwise, the file input onChange handler would not fire if file name is same
  const resetFileInput = () => {
    setInputKey(Math.random().toString(36));
  };

  const uploadSheet = (e) => {
    const { files } = e.target;
    dispatch(updateDefaults(files[0]));
  };

  return (
    <div>
      <input
        aria-label="upload-sheet"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        key={inputKey}
        onChange={(e) => uploadSheet(e)}
      />
      <label style={{ height: '100%' }} htmlFor="raised-button-file">
        <LowerCaseButton
          aria-label="defaults-upload"
          variant="contained"
          color="primary"
          style={{ padding: '0.2rem 1rem' }}
          onClick={resetFileInput}
          component="span"
        >
          Upload & Overwrite
        </LowerCaseButton>
      </label>
      <LowerCaseButton
        aria-label="download"
        variant="contained"
        color="primary"
        style={{ padding: '0.2rem 1rem', margin: '0 0 0 1rem' }}
        onClick={downloadDefaultsSheet}
      >
        Download
      </LowerCaseButton>
    </div>
  );
};

const LowerCaseButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'rgb(85, 108, 120)',
    minWidth: '100px',
    minHeight: '40px',
  },
})(Button);

export default DefaultsAdminButtons;
