import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import Dropzone from 'react-dropzone'

import Dialog from '@material-ui/core/Dialog'

import {showCustomForecast, updateValues} from '../actions'

const XLSX = require('xlsx')

const CustomForecast = () => {
  // Use useSelector Hook to pull from Redux store
  const {status} = useSelector(
    state => ({
      status: state.status,
    }),
    shallowEqual,
  )

  const [file, setFile] = React.useState({})
  const [errorMessage, setErrorMessage] = React.useState('')

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(showCustomForecast(false))
    setErrorMessage('')
    setFile({})
  }

  const handleFiles = files => {
    setFile(files[0])
    console.log(files)
    setErrorMessage('')
  }

  const submitForecast = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const data = new Uint8Array(reader.result)
      const workbook = XLSX.read(data, {type: 'array'})
      if (workbook.SheetNames.find(item => item === 'Forecast')) {
        let forecast = XLSX.utils.sheet_to_json(workbook.Sheets.Forecast)
        console.log('Uploaded data:', forecast)
        if (forecast.find(item => item.Volume)) {
          dispatch(
            updateValues(
              status.customForecastCase,
              'customProdForecast',
              forecast.map(item => item.Volume),
            ),
          )
          dispatch(showCustomForecast(false))
          setErrorMessage('')
        } else {
          setErrorMessage(
            'Error: Invalid format. Forecast sheet must contain a column titled Volume.',
          )
          dispatch(
            updateValues(status.customForecastCase, 'customProdForecast', []),
          )
        }
      } else {
        setErrorMessage(
          'Error: Invalid format. Excel file must contain one sheet named Forecast.',
        )
        dispatch(
          updateValues(status.customForecastCase, 'customProdForecast', []),
        )
      }
      setFile({})
    }

    reader.readAsArrayBuffer(file)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={status.showCustomForecast}
    >
      <div className="customForecastDialog">
        <div className="customForecastHeader">
          {`Custom Forecast for ${status.customForecastCase}`}
        </div>
        {errorMessage !== '' && (
          <div style={{color: 'red', padding: '1rem 0'}}>{errorMessage}</div>
        )}
        <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  Drag & Drop a file, or Click Here for a file selector
                  <br />
                  Excel file expected, containing a worksheet named Forecast
                  with a column named Volume
                  <br />
                  Volume column should contain rows of monthly (not daily)
                  volumes
                  <br />
                  Volume units should be either BBL for oil or MCF for gas,
                  depending on the major phase
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem 0',
            width: '80%',
          }}
        >
          <div>{file?.name && `File Name: ${file.name}`}</div>
          <div className="clear-link">
            {file?.name && (
              <button
                style={{
                  border: '1px solid #3498db',
                  backgroundColor: '#3498db',
                  color: 'white',
                  borderRadius: '2px',
                }}
                onClick={submitForecast}
              >
                Submit
              </button>
            )}
            {!file?.name && (
              <button
                disabled
                style={{
                  border: '1px solid lightgray',
                  backgroundColor: 'lightgray',
                  color: 'white',
                  borderRadius: '2px',
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default CustomForecast
