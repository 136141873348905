import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getSettings, updateSettings } from '../actions';

const Users = () => {
  // Use useSelector Hook to pull from Redux store
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }), shallowEqual);

  const dispatch = useDispatch();

  dispatch(getSettings());

  const updateRole = (role, user) => {
    dispatch(updateSettings('Role', role, user));
  }

  return (
    <div className="users">
      <table style={{ border: '1px solid #DDD', borderRadius: '5px' }}>
          <thead>
              <tr>
                  <th>User</th>
                  <th>Role</th>
                  <th>Action</th>
              </tr>
          </thead>
        <tbody>
          {settings.filter(item => item.User !== 'GLOBAL').map(setting => (
            <tr>
              <td>
                {setting.User}
              </td>
              <td>
                {setting.Role}
              </td>
              <td>
                {setting.Role !== 'Administrator' && (
                  <button type="button" className="clear-link" onClick={() => updateRole('Administrator', setting.User)}>
                    Make Administrator
                  </button>
                )}
                {setting.Role === 'Administrator' && (
                  <button type="button" className="clear-link" onClick={() => updateRole('User', setting.User)}>
                    Remove Admin
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
