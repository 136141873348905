import * as React from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const TooltipIcon = ({tooltip}) => (
  <div className="tooltip-style">
    <Tooltip title={ tooltip }>
      <InfoOutlinedIcon fontSize="inherit" />
    </Tooltip>
  </div>
);

export default TooltipIcon;
