import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'

import {updateSettings, deleteProject} from '../actions'

const UserProjects = ({projectIds}) => {
  const {settings, user} = useSelector(state => {
    return {
      // projects: state.projects,
      settings: state.settings,
      user: state.user,
    }
  }, shallowEqual)

  const dispatch = useDispatch()

  const userSettings = settings.find(item => item.User === user.userName)

  const defaultProject = settings.find(
    setting => setting.User === user.userName,
  )?.defaultProject

  const defaultProjectOwner =
    settings.find(setting => setting.User === 'GLOBAL')?.defaultProjectOwner ||
    projectIds.find(item => item.ProjectName === defaultProject)?.Owner

  const deleteSingleProject = (owner, projectName) => {
    dispatch(deleteProject(owner, projectName))
  }

  const updateUserSettings = event => {
    const {name, value} = event.target
    let items = value.split(' - ')

    if (items.length === 2) {
      dispatch(updateSettings(name, items[0], user.userName))
      dispatch(updateSettings('defaultProjectOwner', items[1], user.userName))
    } else {
      let owner = items.pop()
      let project = items.join(' - ')
      dispatch(updateSettings(name, project, user.userName))
      dispatch(updateSettings('defaultProjectOwner', owner, user.userName))
    }
  }

  return (
    <div
      style={{
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '0 1rem',
          display: 'flex',
          alignItems: 'center',
          fontSize: '24px',
        }}
      >
        <div style={{padding: '0 0.2rem'}}>Default Project </div>
        <Tooltip
          style={{color: 'gray', fontSize: '15px'}}
          title="Default project which will load when app first loads. If none is set, global default project will be used."
        >
          <InfoOutlinedIcon fontSize="inherit" />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          width: '50%',
          margin: '1rem 0',
          justifyContent: 'center',
        }}
      >
        <div>
          <select
            aria-label="change-default-project"
            name="defaultProject"
            value={
              userSettings?.defaultProject && userSettings?.defaultProjectOwner
                ? `${userSettings?.defaultProject} - ${userSettings.defaultProjectOwner}`
                : settings.find(item => item.User === user.userName)
                    ?.defaultProject
                ? `${userSettings?.defaultProject} - ${
                    projectIds.find(
                      item => item.ProjectName === userSettings?.defaultProject,
                    ).Owner
                  }`
                : 'Select default project'
            }
            onChange={e => updateUserSettings(e)}
          >
            <option value="Select default project">
              Select default project
            </option>
            {projectIds
              .filter(item => item.Owner === user.userName) // GoDo, KTE, 4/26/2021:  Consider allowing users to define a default that is a project owned by another user.
              .map(item => (
                <option
                  key={`${item.ProjectName} - ${item.Owner}`}
                  value={`${item.ProjectName} - ${item.Owner}`}
                >{`${item.ProjectName} - ${item.Owner}`}</option>
              ))}
          </select>
        </div>
      </div>
      <div
        style={{
          padding: '1rem',
          display: 'flex',
          alignItems: 'center',
          fontSize: '24px',
        }}
      >
        <div>Manage Projects</div>
      </div>
      <div className="data-table-header" style={{width: '80%'}}>
        {['Project Name', 'Actions'].map(item => (
          <div
            key={item}
            style={{display: 'flex', justifyContent: 'center', width: '50%'}}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="data-table-container" style={{width: '80%'}}>
        <table>
          <tbody style={{maxHeight: '490px', overflowY: 'scroll'}}>
            {projectIds
              .filter(item => item.Owner === user.userName)
              ?.map(item => (
                <tr key={item.ProjectName + '-' + item.User}>
                  <td style={{paddingLeft: '0.4rem'}}>{item.ProjectName}</td>
                  <td>
                    {!(
                      defaultProject === item.ProjectName &&
                      defaultProjectOwner === item.Owner
                    ) ? (
                      <button
                        className="clear-link"
                        style={{fontSize: '14px'}}
                        onClick={() =>
                          deleteSingleProject(item.Owner, item.ProjectName)
                        }
                      >
                        Delete
                      </button>
                    ) : (
                      <button disabled>Default Project (can't delete)</button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UserProjects
