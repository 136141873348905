import * as React from 'react'
import {shallowEqual, useSelector} from 'react-redux'

import PlotLineGraph from './PlotLineGraph'

import {currencyFormatter} from '../utils'
import {formatHEConomicsNumber} from '../utils/formatHEConomicsNumber'

const Prices = () => {
  const {
    subPage,
    pricingInputs,
    priceDiffOil,
    priceDiffGas,
    nglPricePercent,
  } = useSelector(
    state => ({
      subPage: state.navigation.subPage,
      pricingInputs: state.pricingInputs,
      priceDiffOil: state.projectInputs.priceDiffOil,
      priceDiffGas: state.projectInputs.priceDiffGas,
      nglPricePercent: state.projectInputs.nglPricePercent,
    }),
    shallowEqual,
  )

  const renderTable = data => (
    <div className="data-table-container">
      <table>
        <tbody>
          {data.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                {/* {currencyFormatter(item, 2)} */}
                {formatHEConomicsNumber(true, 2, item)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  const renderTablePercentage = data => (
    <div className="data-table-container">
      <table>
        <tbody>
          {data.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                {formatHEConomicsNumber(false, 2, item)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  const oilBasePrice = [
    {
      name: 'Oil Base Price',
      nameCssClass: 'oilBasePrice',
      monthlyData: pricingInputs.Oil,
    },
  ]
  const gasBasePrice = [
    {
      name: 'Gas Base Price',
      nameCssClass: 'gasBasePrice',
      monthlyData: pricingInputs.Gas,
    },
  ]
  const oilDiff = [
    {
      name: 'Oil Diff',
      nameCssClass: 'oilDiff',
      monthlyData: pricingInputs.Oil.map(() => priceDiffOil),
    },
  ]
  const oilWellheadPrice = [
    {
      name: 'Oil Wellhead Price',
      nameCssClass: 'oilWellheadPrice',
      monthlyData: pricingInputs.Oil.map(
        item => Number(item) + Number(priceDiffOil),
      ),
    },
  ]
  const gasDiff = [
    {
      name: 'Gas Diff',
      nameCssClass: 'gasDiff',
      monthlyData: pricingInputs.Gas.map(() => priceDiffGas),
    },
  ]
  const gasWellheadPrice = [
    {
      name: 'Gas Wellhead Price',
      nameCssClass: 'gasWellheadPrice',
      monthlyData: pricingInputs.Gas.map(
        item => Number(item) + Number(priceDiffGas),
      ),
    },
  ]
  const nglPricePercentArray = [
    {
      name: 'NGL Price Percent',
      nameCssClass: 'nglPricePercent',
      monthlyData: pricingInputs.Oil.map(item => Number(nglPricePercent))
    }
  ]
  const nglWellheadPrice = [
    {
      name: 'NGL Wellhead Price',
      nameCssClass: 'nglWellheadPrice',
      monthlyData: pricingInputs.Oil.map(
        item =>
          (Number(item)) *
          (Number(nglPricePercent) / 100)
      ),
    },
  ]

  return (
    <div
      className="full-data-grid"
      style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}
    >
      {subPage === 'Oil Base Price' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={oilBasePrice}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Oil Base Price ($ per BBL)"
            yAxisType="Cartesian"
            title="Oil Base Price"
            subTitle="$ per BBL"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'Gas Base Price' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={gasBasePrice}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Gas Base Price ($ per MCF)"
            yAxisType="Cartesian"
            title="Gas Base Price"
            subTitle="$ per MCF"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'Oil Diff' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={oilDiff}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Oil Diff ($ per BBL)"
            yAxisType="Cartesian"
            title="Oil Diff"
            subTitle="$ per BBL"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'Oil Wellhead Price' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={oilWellheadPrice}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Oil Wellhead Price ($ per BBL)"
            yAxisType="Cartesian"
            title="Oil Wellhead Price"
            subTitle="$ per BBL"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'Gas Diff' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={gasDiff}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Gas Diff ($ per MCF)"
            yAxisType="Cartesian"
            title="Gas Diff"
            subTitle="$ per MCF"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'Gas Wellhead Price' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={gasWellheadPrice}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="Gas Wellhead Price ($ per MCF)"
            yAxisType="Cartesian"
            title="Gas Wellhead Price"
            subTitle="$ per MCF"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'NGL Price Percentage' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={nglPricePercentArray}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="NGL Price Percent"
            yAxisType="Cartesian"
            title="NGL Price Percent"
            subTitle="% vs. Time"
            showLegend={false}
          />
        </div>
      )}
      {subPage === 'NGL Wellhead Price' && (
        <div className="graphs">
          <PlotLineGraph
            plotDataArray={nglWellheadPrice}
            width="1000"
            height="540"
            xAxisLabel="Year"
            yAxisLabel="NGL Wellhead Price ($ per BBL)"
            yAxisType="Cartesian"
            title="NGL Wellhead Price"
            subTitle="$ per BBL"
            showLegend={false}
          />
        </div>
      )}
      <div style={{maxWidth: '500px', width: '50%'}}>
      {subPage !== 'NGL Price Percentage' && (
        <div className="data-table-header">
          {['Month', 'Price'].map(item => (
            <div
              key={item}
              style={{display: 'flex', justifyContent: 'center', width: '11%'}}
            >
              {item}
            </div>
          ))}
        </div>)}
        {subPage === 'NGL Price Percentage' && (
          <div className="data-table-header">
          {['Month', 'Price Percentage'].map(item => (
            <div
              key={item}
              style={{display: 'flex', justifyContent: 'center', width: '11%'}}
            >
              {item}
            </div>
          ))}
        </div>
        )}
        {subPage === 'Oil Base Price' && renderTable(pricingInputs.Oil)}
        {subPage === 'Gas Base Price' && renderTable(pricingInputs.Gas)}
        {subPage === 'Oil Diff' &&
          renderTable(pricingInputs.Oil.map(() => Number(priceDiffOil)))}
        {subPage === 'Oil Wellhead Price' &&
          renderTable(
            pricingInputs.Oil.map(item => Number(item) + Number(priceDiffOil)),
          )}
        {subPage === 'Gas Diff' &&
          renderTable(pricingInputs.Gas.map(() => Number(priceDiffGas)))}
        {subPage === 'Gas Wellhead Price' &&
          renderTable(
            pricingInputs.Gas.map(item => Number(item) + Number(priceDiffGas)),
          )}
        {subPage === 'NGL Price Percentage' &&
          renderTablePercentage(pricingInputs.Oil.map(item => Number(nglPricePercent)))}
        {subPage === 'NGL Wellhead Price' &&
          renderTable(
            pricingInputs.Oil.map(
              item =>
                (Number(item)) *
                (Number(nglPricePercent) / 100),
            ),
          )}
      </div>
    </div>
  )
}

export default Prices
