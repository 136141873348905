/* Third party NPM packages */
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

/* Imports of local components */
import GlobalSettings from './GlobalSettings';
import DifferentialsTable from './DifferentialsTable';
import TaxesTable from './TaxesTable';
import PricesTable from './PricesTable';
import Users from './Users';
import DefaultsAdminButtons from './DefaultsAdminButtons';
import TaxesAdminButtons from './TaxesAdminButtons';
import PricesAdminButtons from './PricesAdminButtons';

/* Import action creators for Redux */
import { 
  deletePriceDeck,
  updatePriceFilter
} from '../actions';

/* Component-specific style sheet imports */
import '../styles/admin.css';

/* Legacy require statements for third party packages */
const _ = require('lodash');
const XLSX = require('xlsx');

const Admin = () => {
  // Use useSelector Hook to pull from Redux store
  const { subPage, prices, status } = useSelector((state) => ({
    subPage: state.navigation.subPage,
    prices: state.prices,
    status: state.status,
  }), shallowEqual);

  const dispatch = useDispatch();

  const deleteSinglePriceDeck = () => {
    dispatch(deletePriceDeck(status.priceFilter, prices));
  }

  const handleFilterChange = (value) => {
    dispatch(updatePriceFilter(value));
  }

  return (
    <div className="admin-all">
      <div className="admin-main">
        <div className="admin-header">
          <div className="admin-filters">
            {subPage === 'Prices' && (
              <div className="price-filter">
              Price Deck: <select value={status.priceFilter} onChange={(e) => handleFilterChange(e.target.value)}>
                <option value="Select Price Deck">Select Price Deck</option>
                {prices?.map((item, index) => <option key={index} value={item.PriceName}>{item.PriceName}</option>)}
              </select>
            </div>
            
            )}
            {subPage === 'Prices' && status.priceFilter !== 'Select Price Deck' && (
              <button className="clear-link" onClick={deleteSinglePriceDeck}>Delete this price deck</button>
            )}
          </div>
          <div className="admin-buttons">
            {subPage === 'Diffs & OPEX' && <DefaultsAdminButtons />}
            {subPage === 'Taxes' && <TaxesAdminButtons />}
            {subPage === 'Prices' && <PricesAdminButtons />}
          </div>
        </div>
        <div className="admin-message">
          {subPage === 'Diffs & OPEX' && <Alert severity="info">
            <p>Differentials and OPEX values may be edited in bulk. To edit the following table, `Download` the existing values, modify them in Excel, then `Upload & Overwrite`.</p>
            <p>Note that table rows are NOT deleted when you `Upload & Overwrite`.  To delete, recruit a HEConomics developer or Hilcorp IT teammate to remove rows via the AWS DynamoDB management console.</p>
          </Alert>
          }
          {subPage === 'Taxes' && <Alert severity="info"> Tax values may be edited in bulk. To edit the below table, download the existing values, modify them in Excel and upload them here.</Alert>}
          {subPage === 'Prices' && <Alert severity="info">Price Decks are currently viewable and editable by Price Deck. To edit a particular price deck, choose a price deck from the dropdown menu, download the existing values, modify them in Excel and upload them using the same name.</Alert>}
        </div>
        <div className="admin-detail">
          {subPage === 'Diffs & OPEX' && <DifferentialsTable />}
          {subPage === 'Taxes' && <TaxesTable />}
          {subPage === 'Prices' && <PricesTable />}
          {subPage === 'Global Settings' && <GlobalSettings />}
          {subPage === 'Users' && <Users />}
        </div>
      </div>
    </div>
  )
}

export default Admin;
