/* 
/ Name: Global Settings
/ Purpose: Allow administrators to view and modify settings that apply to all users. Current
/ settings include the default project to load when a user accesses the application (if they
/ do not have a default project of their own configured), as well as which projects to show
/ to all users as examples in their load project window.
*/

/* Third party NPM packages */
import {CircularProgress} from '@material-ui/core'
import * as React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

/* Import action creators for Redux */
import {deleteProject, makeProjectPublic, updateSettings} from '../actions'

/* Legacy require statements for third party packages */
const _ = require('lodash')

const GlobalSettings = () => {
  const {user, projectIds, settings, isGettingProjectIds} = useSelector(
    state => {
      return {
        user: state.user,
        projectIds: state.projectIds,
        settings: state.settings,
        isGettingProjectIds: state.status.isGettingProjectIds,
      }
    },
    shallowEqual,
  )

  const dispatch = useDispatch()

  /**
   * KTE, 3/27/2021:  I disabled the call to this action creator.  I need to refactor the action, to accept
   * project identification info, and not the whole project.  I'll get to this later.  For now, I'm simply
   * disabling this feature.
   */
  const makePublic = project => {
    dispatch(makeProjectPublic(project))
  }

  const deleteSingleProject = (owner, projectName) => {
    dispatch(deleteProject(owner, projectName))
  }

  const defaultProject = settings.find(setting => setting.User === 'GLOBAL')
    ?.defaultProject

  const defaultProjectOwner =
    projectIds &&
    (settings.find(setting => setting.User === 'GLOBAL')?.defaultProjectOwner ||
      projectIds.find(item => item.ProjectName === defaultProject)?.Owner)

  const sortedProjectIds =
    projectIds &&
    _.orderBy(projectIds, ['Owner', 'ProjectName'], ['asc', 'asc'])

  const updateGlobalSettings = event => {
    const {name, value} = event.target
    let items = value.split(' - ')

    if (items.length === 2) {
      dispatch(updateSettings(name, items[0], user.userName))
      dispatch(updateSettings('defaultProjectOwner', items[1], 'GLOBAL'))
    } else {
      let owner = items.pop()
      let project = items.join(' - ')
      dispatch(updateSettings(name, project, user.userName))
      dispatch(updateSettings('defaultProjectOwner', owner, 'GLOBAL'))
    }
  }

  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <div className="settings">
        {isGettingProjectIds ? (
          <CircularProgress />
        ) : (
          <>
            <div style={{display: 'flex', width: '100%'}}>
              <div
                style={{
                  padding: '0 1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '50%',
                }}
              >
                Global Default Project (disabled by Natalie and Brent in April
                2021; users can define their own defaults, but we will not
                assign global defaults):
              </div>
              <div>
                <select
                  disabled // KTE, 4/26/2021: Disabled this feature, since Natalie Brent and I decided not to give users an automatic default
                  name="defaultProject"
                  value={
                    `${defaultProject} - ${defaultProjectOwner}` ||
                    'Select default project'
                  }
                  onChange={e => updateGlobalSettings(e)}
                >
                  <option value="Select default project">
                    Select default project
                  </option>
                  {sortedProjectIds?.map(item => (
                    <option
                      key={`${item.ProjectName} - ${item.Owner}`}
                      value={`${item.ProjectName} - ${item.Owner}`}
                    >{`${item.ProjectName} - ${item.Owner}`}</option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{display: 'flex', width: '100%', marginTop: '2rem'}}>
              <div
                style={{
                  padding: '0 1rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-end',
                  width: '20%',
                }}
              >
                All projects:
              </div>
              <div className="settings-table">
                <div className="data-table-header">
                  {['Project Name', 'Project Owner', 'Actions', ' '].map(
                    item => (
                      <div
                        key={item}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '30%',
                        }}
                      >
                        {item}
                      </div>
                    ),
                  )}
                </div>
                <div className="data-table-container">
                  <table>
                    <tbody style={{maxHeight: '490px', overflowY: 'scroll'}}>
                      {sortedProjectIds?.map(item => (
                        <tr key={item.Owner + '_' + item.ProjectName}>
                          <td style={{paddingLeft: '0.4rem'}}>
                            {item.ProjectName}
                          </td>
                          <td>{item.Owner}</td>
                          <td>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              {item.Owner !== 'All Users' && (
                                <button
                                  disabled={true}
                                  className="clear-link"
                                  style={{fontSize: '14px'}}
                                  onClick={() => makePublic(item)}
                                >
                                  Publish as Example (Temporarily Disabled)
                                </button>
                              )}
                              {item.Owner === 'All Users' && (
                                <div style={{width: '100px'}}>{` `}</div>
                              )}
                            </div>
                          </td>
                          <td>
                            {!(
                              defaultProject === item.ProjectName &&
                              defaultProjectOwner === item.Owner
                            ) && (
                              <button
                                className="clear-link"
                                style={{fontSize: '14px'}}
                                onClick={() =>
                                  deleteSingleProject(
                                    item.Owner,
                                    item.ProjectName,
                                  )
                                }
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default GlobalSettings
